import authedAxios from './auth-axios';

export interface SubscriptionInfo {
  id: string;
  plan: string;
  canceled: boolean;
  nextBillingDate: number;
  amount: number;
}

export const getSubscriptionInfo = async (userId: string): Promise<SubscriptionInfo[]> => {
  try {
    const response = await authedAxios.get(`${process.env.REACT_APP_BACKEND_URL}/subscription/info/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching subscription info:', error);
    throw error;
  }
};

export const cancelSubscription = async (userId: string, subscriptionId: string): Promise<boolean> => {
  try {
    const response = await authedAxios.post(`${process.env.REACT_APP_BACKEND_URL}/subscription/cancellation/${userId}/${subscriptionId}`);
    return response.status === 200;
  } catch (error) {
    console.error('Error cancelling subscription:', error);
    throw error;
  }
};