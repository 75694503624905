import axios from 'axios';
import authedAxios from './auth-axios';
import { BackendTemplate } from './templates';

export async function fetchUserType(user_id: string): Promise<string> {
  try {
    const response = await authedAxios.get(`/users/${user_id}/type`);
    return response.data.type;
  }
  catch (error) {
    console.error('Error fetching user type:', error);
    throw error;
  }
}

type CreditUsage = {
  max_sent_messages: number
  current_sent_messages: number
}

export async function getUsage(user_id: string): Promise<CreditUsage> {
  try {
    const response = await authedAxios.get(`/users/${user_id}/usage`);
    return response.data as CreditUsage;
  }
  catch (error) {
    console.error('Error fetching user type:', error);
    throw error;
  }
}

type TotalStudentUsage = {
  total_student_usage: number
}

export const getTotalStudentUsage = async (
  user_id: string, 
  assigned_student_id_list: string[]): Promise<TotalStudentUsage> => {
  try {
    // If list is empty, return empty result immediately
    if (assigned_student_id_list.length === 0) {
      return { total_student_usage: 0 };
    }

    const queryParams = new URLSearchParams();
    assigned_student_id_list.forEach(id => queryParams.append('assigned_student_id_list', id));
    const response = await authedAxios.get(
      `/users/${user_id}/student-usage?${queryParams.toString()}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching student usage:', error);
    throw error;
  }
};

export const verifyToken = async (): Promise<boolean> => {
  try {
    const token = localStorage.getItem('access_token');
    if (!token) return false;

    await authedAxios.get('/verify-token');
    return true;
  } catch (error) {
    // Let the interceptor handle 401 errors and token refresh
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      return false;
    }
    console.error('Token verification failed', error);
    return false;
  }
};

export const update_user_config = async (
  user_id: string,
  course_ids: string[],
  organization: string,
  userNumberCap: number,
  userMessageCap: number,
  orgMessageCap: number,
  courseTerminology: string,
  moduleTerminology: string,
  sourcesVisible: boolean,
  filterBySource: boolean,
  filterByGroup: boolean,
): Promise<boolean> => {
  try {
    const response = await authedAxios.post(`/users/${user_id}/update-user-config`, {
      auto_enroll_students_to_courses: course_ids,
      organization: organization,
      userNumberCap: userNumberCap,
      userMessageCap: userMessageCap,
      orgMessageCap: orgMessageCap,
      courseTerminology: courseTerminology,
      moduleTerminology: moduleTerminology,
      sourcesVisible: sourcesVisible,
      filterBySource: filterBySource,
      filterByGroup: filterByGroup,
    });
    return response.status === 200;
  } catch (error) {
    console.error('Error updating auto enroll:', error);
    throw error;
  }
}

export const fetchAssignedStudents = async (
  user_id: string,
  assigned_student_id_list: string[]
) => {
  try {
    // If list is empty, return empty result immediately
    if (assigned_student_id_list.length === 0) {
      return { assigned_students: [] };
    }
    
    const queryParams = new URLSearchParams();
    assigned_student_id_list.forEach(id => queryParams.append('assigned_student_id_list', id));
    const response = await authedAxios.get(`/users/${user_id}/assigned_students?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    console.error('Error updating assigned students:', error);
    throw error;
  }
};

type AssignedStudent = {
  name: string
  email: string
  last_updated: string
}

type AssignedStudents = {
  assigned_students: AssignedStudent[]
}

export const removeSelectedStudents = async (
  user_id: string,
  assigned_student_id_list: string[],
  student_ids_selected_for_removal: string[],
  course_id_list: string[]
): Promise<AssignedStudents> => {
  try {
    const response = await authedAxios.post(`/users/${user_id}/remove`, {
      assigned_student_id_list: assigned_student_id_list,
      student_ids_selected_for_removal: student_ids_selected_for_removal,
      course_id_list: course_id_list
    });
    return response.data;
  } catch (error) {
    console.error('Error removing students:', error);
    throw error;
  }
}


export const updateUserTemplates = async (userId: string, templates: BackendTemplate[]): Promise<boolean> => {
  try {
    const response = await authedAxios.put(`/users/${userId}/update_user_templates`, {
      templates: templates
    });
    return response.status === 200;
  } catch (error) {
    console.error('Error updating user templates:', error);
    return false;
  }
}

export const updatePassword = async (userId: string, { old_password, new_password }: any) => {
  const response = await authedAxios.put(`/users/${userId}/update-password`, {
    old_password: old_password,
    new_password: new_password,
  });

  if (response.status !== 200) {
    throw new Error('Failed to update password');
  }

  return response.status === 200;
}

export const updateUserName = async (user_id: string, { name }: { name: string }): Promise<boolean> => {
  try {
    const response = await authedAxios.put(`/users/${user_id}/update-name`, {
      name: name
    });
    return response.status === 200;
  } catch (error) {
    console.error('Error updating user name:', error);
    throw error;
  }
};

export const updateUserEmail = async (user_id: string, { email }: { email: string }): Promise<boolean> => {
  try {
    const response = await authedAxios.put(`/users/${user_id}/update-email`, {
      email: email
    });
    return response.status === 200;
  } catch (error) {
    console.error('Error updating user email:', error);
    throw error;
  }
};