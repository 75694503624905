import React from 'react';
import { Content } from 'antd/es/layout/layout';
import { Typography, Card } from 'antd';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';
import SubscriptionTable from '../../components/subscription/SubscriptionTable';

const { Title } = Typography;

const SubscriptionManagement = () => {
  
  return (
    <Content style={{ padding: "2rem" }}>
      <Title level={2}>Subscription Management</Title>
      <Card style={{ marginTop: '1rem' }}>
        <SubscriptionTable />
      </Card>
    </Content>
  );
};

export default SubscriptionManagement;