// StudentTable.tsx
import React from 'react';
import { Table } from 'antd';
import { Student } from '../../services/courses';
import { FlexMarginButton } from '../basic/buttons';

interface StudentTableProps {
    assigned_students: Student[];
    enrolledStudents: Student[];
    onStudentEnroll: (student: Student) => void;
    onStudentUnenroll: (student: Student) => void;
}

const StudentTable: React.FC<StudentTableProps> = ({ assigned_students, enrolledStudents, onStudentEnroll, onStudentUnenroll }) => {
    const columns = [
        {
            title: 'Action',
            key: 'action',
            render: (text: string, student: Student) => (
                enrolledStudents.find(s => s._id === student._id)
                ? <FlexMarginButton onClick={() => onStudentUnenroll(student)}>Remove</FlexMarginButton>
                : <FlexMarginButton onClick={() => onStudentEnroll(student)}>Enroll</FlexMarginButton>
            ),
            width: '10%',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '45%',
        },
        {
            title: 'Status',
            key: 'status',
            render: (text: string, student: Student) => (
                enrolledStudents.find(s => s._id === student._id) ? 'Enrolled' : 'Available'
            ),
            width: '45%',
        },
    ];

    return (
        <div>
            <h2>Students</h2>
            <Table columns={columns} dataSource={assigned_students} rowKey="_id" />
        </div>
    );
};

export default StudentTable;