import { styled } from "@stitches/react"
import { Input } from "antd"
import { NoMarginButton } from "./buttons"

export const SendContainer = styled("div", {
    width: '80vh',
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center', // Centers children horizontally
    justifyContent: 'center', // Centers children vertically
    maxHeight: '100px',
    flexShrink: 0,
    maxWidth: '100%',
    margin: '0 auto', // Center the container
})

export const SendInput = styled(Input.TextArea, {
    borderRadius: '8px',
    // maxWidth: '50vh', // Ensure it doesn't exceed container width
    width: '70vh',
})

export const SendButton = styled(NoMarginButton, {
    position: "absolute",
    right: "0",
    bottom: "0px",
    zIndex: 1,
})