import React from 'react';

const HealthCheck = () => {
  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-2">Health Status</h2>
      <div className="text-green-500">Status: Healthy</div>
      <div className="mt-2 text-gray-600">Server Time: {new Date().toISOString()}</div>
    </div>
  );
};

export default HealthCheck;