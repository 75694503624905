import { useState, useEffect } from 'react';
import { getUserDataFromLocalStorage, setUserDataInLocalStorage } from '../utils/useLocalStorage';
import { BackendTemplate } from '../services/templates';

const useUserData = () => {
    const [user, setUser] = useState(() => getUserDataFromLocalStorage());

    const updateUserDataTemplates = (templates: BackendTemplate[]) => {
        const updatedUserData = { ...user, templates };
        setUser(updatedUserData);
        setUserDataInLocalStorage(updatedUserData);
    };

    useEffect(() => {
        const storedUserData = getUserDataFromLocalStorage();
        setUser(storedUserData);
    }, []);

    return { user, updateUserDataTemplates };
};

export default useUserData;