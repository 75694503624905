import React, { useEffect, useState } from 'react';
import { Modal, Input, Button, Form, Table, Select, Row, Col } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { Template, VariableDescription } from '../../services/templates';


export const extractVariables = (templateText: string, tableData: VariableDescription[]): VariableDescription[] => {
    const variableRegex = /\{([^{}]+?)\}/g;
    let match: RegExpExecArray | null;
    const allVariables: string[] = [];
    const variables: VariableDescription[] = [];

    // Extract all unique variables from the template text
    while ((match = variableRegex.exec(templateText)) !== null) {
        if (match[1]) { // Ensure there is a captured group
            const variable = match[1].trim();
            if (!allVariables.includes(variable)) {
                allVariables.push(variable);
            }
        }
    }

    // Match each variable to the existing ones in tableData and assign descriptions
    allVariables.forEach(variable => {
        const tableEntry = tableData.find(entry => entry.variable === variable);
        const description = tableEntry ? tableEntry.description : '';
        variables.push({ variable: variable, description: description });
    });

    return variables;
}


interface TemplateCreatorProps {
    visible: boolean;
    onClose: () => void;
    onSave: (template: Template) => boolean;
    template?: Template | null;
    type?: string; // Made type optional
}

const TemplateCreator: React.FC<TemplateCreatorProps> = ({ visible, onClose, onSave, template, type }) => {
    const [templateUIKey, setTemplateUIKey] = useState(uuidv4() as string);
    const [templateName, setTemplateName] = useState('');
    const [templateText, setTemplateText] = useState('');
    const [tableData, setTableData] = useState<VariableDescription[]>([]);
    const [nameError, setNameError] = useState<string | null>(null);
    const [selectedType, setSelectedType] = useState<string>(type || 'content');

    useEffect(() => {
        if (visible) {
            if (template) {
                setTemplateUIKey(template.ui_key || uuidv4());
                setTemplateName(template.name);
                setTemplateText(template.text);
                setTableData(template.variables);
                setSelectedType(template.type || 'content');
            } else {
                setTemplateUIKey(uuidv4());
                setTemplateName('');
                setTemplateText('');
                setTableData([]);
                setSelectedType(type || 'content');
            }
        }
    }, [visible, template, type]);

    const handleSave = () => {
        if (templateName.trim() === '') {
            setNameError('Please enter a template name.');
            return;
        }

        const newTemplate: Template = {
            ui_key: templateUIKey,
            name: templateName,
            text: templateText,
            variables: tableData,
            type: selectedType
        };

        const saveSuccessful = onSave(newTemplate);
        if (saveSuccessful) {
            setNameError(null);
            onClose();
        } else {
            setNameError('A template with this name already exists.');
        }
    };

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTemplateText(e.target.value);
        const extractedVariables = extractVariables(e.target.value, tableData);

        const newTableData = extractedVariables.map(variable => ({
            key: variable.variable,
            variable: variable.variable,
            description: variable.description,
        }));

        setTableData(newTableData);
    };

    const columns = [
        {
            title: 'Variable',
            dataIndex: 'variable',
            key: 'variable',
            render: (text: string) => <strong>{text}</strong>,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (_: any, record: { variable: string, description: string }) => (
                <Input
                    value={record.description}
                    onChange={e => {
                        const newData = tableData.map(item =>
                            item.variable === record.variable ? { ...item, description: e.target.value } : item
                        );
                        setTableData(newData);
                    }}
                />
            )
        }
    ];

    return (
        <Modal
            title={template ? "Edit Template" : "+ New Template"}
            open={visible}
            onCancel={onClose}
            footer={null}
            style={{
                minWidth: '50%',
                maxWidth: '50%',
                margin: '0 auto',
                top: '10vh',
                bottom: '10vh'
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {nameError && <div style={{ color: 'red', marginBottom: '10px' }}>{nameError}</div>}
                <Row gutter={16}
                    style={{
                        marginBottom: '20px',
                        width: '100%'
                    }}>
                    <Col span={12} style={{ padding: 0 }}>
                        <Input
                            placeholder="Template Name"
                            value={templateName}
                            style={{ width: '100%' }}
                            onChange={e => {
                                setTemplateName(e.target.value);
                                setNameError(null);
                            }}
                        />
                    </Col>
                    <Col span={12} style={{ padding: 0 }}>
                        <Select
                            style={{ width: '100%' }}
                            value={selectedType}
                            onChange={(value) => setSelectedType(value)}
                            disabled={!!type}
                        >
                            <Select.Option value="content">Content</Select.Option>
                            <Select.Option value="analytics">Analytics</Select.Option>
                        </Select>
                    </Col>
                </Row>
                <Input.TextArea
                    rows={10}
                    placeholder="You can denote variables using curly braces, e.g. {variable_name}"
                    style={{
                        marginBottom: '20px',
                        resize: 'none'
                    }}
                    value={templateText}
                    onChange={handleTextChange}
                />
                <Form.Item style={{ width: '100%' }}>
                    {tableData.length > 0 && (
                        <Table
                            dataSource={tableData}
                            columns={columns}
                            pagination={false}
                            style={{ width: '100%', minHeight: '230px', maxHeight: '230px', overflow: 'auto' }}
                        />
                    )}
                </Form.Item>
                <Button
                    type="primary"
                    onClick={handleSave}
                    style={{ textAlign: 'center' }}
                >
                    Save Template
                </Button>
            </div>
        </Modal>
    );
};

export default TemplateCreator;