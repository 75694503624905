
export function isEmptyOrOnlyTags(str: string): boolean {
    // Trim the string to remove leading and trailing whitespace
    const trimmed = str.trim();
    
    // Check if the string is empty after trimming
    if (trimmed === '') {
      return true;
    }
    
    // Remove all HTML tags
    const withoutTags = trimmed.replace(/<[^>]*>/g, '');
    
    // Remove all whitespace characters (spaces, tabs, newlines)
    const withoutWhitespace = withoutTags.replace(/\s/g, '');
    
    // If the resulting string is empty, it means the original string
    // contained only HTML tags and/or whitespace
    return withoutWhitespace === '';
  }