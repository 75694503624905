import { TopImages } from "../components/session-styles";
import { AbsoluteCenter, FullPage } from "../components/styles";
import { useEffect, useRef } from "react";

const BackendUnavailable = () => {
    const fullPageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const preventZoom = (e: KeyboardEvent | WheelEvent) => {
            if (('ctrlKey' in e && e.ctrlKey) || ('metaKey' in e && e.metaKey)) { // Check if Ctrl or Cmd key is pressed
                e.preventDefault();
            }
        };

        const currentRef = fullPageRef.current;

        if (currentRef) {
            currentRef.addEventListener('wheel', preventZoom, { passive: false });
            currentRef.addEventListener('keydown', preventZoom, { passive: false });
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('wheel', preventZoom);
                currentRef.removeEventListener('keydown', preventZoom);
            }
        };
    }, []);

    return (
        <FullPage ref={fullPageRef}>
            <TopImages />
            <AbsoluteCenter style={{ textAlign: "center" }}>
                <p style={{
                    color: '#FFFFFF'
                }}>
                    <strong>
                        Sorry, the app is currentely under maintenance. We will be back shortly!
                    </strong>
                </p>
            </AbsoluteCenter>
        </FullPage>
    );
};

export default BackendUnavailable;

