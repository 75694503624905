import React, { useEffect, useRef } from 'react';
import TopicUnderstanding from './understanding-report';
import Engagement from './engagement-report';
import { Divider } from 'antd';
import CourseMaterialReport from './course-material-report';

interface CourseReportsPageProps {}

const CourseReportsPage: React.FC<CourseReportsPageProps> = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, []);

  return (
    <div ref={containerRef} style={{ height: '100%', overflowY: 'auto', padding: '24px' }}>
      <TopicUnderstanding />
      <Divider orientation="right" orientationMargin={50} />
      <Engagement />
      <Divider orientation="right" orientationMargin={50} />
      <CourseMaterialReport />
    </div>
  );
};

export default CourseReportsPage;