import React, { useState, useEffect } from 'react';
import { Table, Button, message } from 'antd';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';
import { getSubscriptionInfo, cancelSubscription, SubscriptionInfo } from '../../services/subscription';

const SubscriptionTable = () => {
    const [subscriptions, setSubscriptions] = useState<SubscriptionInfo[]>([]);
    const [loading, setLoading] = useState(true);
    const user = getUserDataFromLocalStorage();

    useEffect(() => {
        fetchSubscriptions();
    }, []);

    const fetchSubscriptions = async () => {
        try {
            const data = await getSubscriptionInfo(user._id);
            setSubscriptions(data);
        } catch (error) {
            message.error('Failed to load subscription information');
        } finally {
            setLoading(false);
        }
    };

    const handleCancelSubscription = async (subscriptionId: string) => {
        try {
            await cancelSubscription(user._id, subscriptionId);
            message.success('Subscription cancelled successfully');
            fetchSubscriptions(); // Refresh the subscription list
        } catch (error) {
            message.error('Failed to cancel subscription');
        }
    };

    const columns = [
        {
            title: 'Plan',
            dataIndex: 'plan',
            key: 'plan',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Next Billing Date',
            dataIndex: 'nextBillingDate',
            key: 'nextBillingDate',
            render: (date: number) => new Date(date * 1000).toLocaleDateString(),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => `$${amount.toFixed(2)}`,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: { id: any; canceled: boolean; }) => (
                <Button
                    danger
                    onClick={() => handleCancelSubscription(record.id)}
                    disabled={record.canceled}
                >
                    {record.canceled ? "Already canceled" : "Cancel Subscription" }
                </Button>
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={subscriptions}
            loading={loading}
            rowKey="id"
        />
    );
};

export default SubscriptionTable;
