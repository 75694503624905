import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Card, Typography, Space, Row, Col, InputNumber, Select } from 'antd';
import { useFormik } from 'formik';
import { useNavigate, Link } from 'react-router-dom';
import { SunOutlined, SunFilled } from '@ant-design/icons';
import { Container, SignupFormContainer, TopImages } from '../../components/session-styles';
import { AbsoluteCenter } from '../../components/styles';
import { SignupProps } from '../../screens/signup/signup';

const { Title, Text } = Typography;

export interface SignupFormProps {
    loginCallback: () => void;
}

interface UserTypeOption {
    type: string;
    title: string;
    description: string[];
}

const userTypeOptions: UserTypeOption[] = [
    {
        type: "student",
        title: "Student",
        description: [
            "Access to your educator's chatbot",
            "Use it without hallucinations",
            "Filter responses and get precise sources"
        ]
    },
    {
        type: "autodidact",
        title: "Autodidact",
        description: [
            "Create a chatbot for your own materials",
            "Use it with all the perks of a student",
            "Invite your study partners",
            "Create module summaries and test yourself"
        ]
    },
    {
        type: "professor",
        title: "Educator",
        description: [
            "Create a chatbot for your own materials",
            "Enable your students to learn effectively with AI",
            "Create assignments suitable for AI",
            "Understand your students' needs using our analytics",
            "Get scheduled reports to improve your teaching"
        ]
    }
];

const SignupForm: React.FC<SignupFormProps> = ({ loginCallback }) => {
    const [userOption, setUserOption] = useState<UserTypeOption | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [emailExists, setEmailExists] = useState(false);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
            educator_email: '',
            number_of_students: 0,
            number_of_messages: 0,
        },
        validate: (values) => {
            let errors: Record<string, string> = {};
            if (!values.name) {
                errors.name = 'Please enter your name.';
            }
            if (!values.email) {
                errors.email = 'Please enter your email.';
            }
            if (!values.password) {
                errors.password = 'Please enter your password.';
            }
            if (values.password !== values.confirmPassword) {
                errors.confirmPassword = 'Passwords do not match.';
            }
            if (userOption) {
                if (userOption.type === 'student' && !values.educator_email) {
                    errors.educator_email = 'Please enter the email of your educator.';
                }
                if (userOption.type === 'professor'
                    && (values.number_of_students === undefined || values.number_of_students < 0)) {
                    errors.number_of_students = 'Please enter a valid number of students.';
                }
                if (userOption.type === 'professor'
                    && (values.number_of_messages === undefined || values.number_of_messages < 0)) {
                    errors.number_of_messages = 'Please choose a number from the selection.';
                }
            }
            if (emailExists) {
                errors.email = 'This email is already in use.';
            }
            return errors;
        },
        onSubmit: (values) => {
            if (emailExists) {
                message.error('This email is already in use. Please use a different email.');
                return;
            }
            setIsLoading(true);
            if (userOption!.type === 'student') {
                // Existing student signup logic
                fetch(`${process.env.REACT_APP_BACKEND_URL}/signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name: values.name,
                        email: values.email,
                        username: values.email,
                        password: values.password,
                        educator_email: values.educator_email,
                        number_of_studnets: values.number_of_students,
                        number_of_messages: values.number_of_messages,
                        type: 'student',
                        payment_session_id: ''
                    }),
                })
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error("Signup failed. Please check your details and try again.");
                        }
                        return response.json();
                    })
                    .then((data) => {
                        localStorage.setItem('user', JSON.stringify(data.user));
                        localStorage.setItem('access_token', data.access_token);
                        loginCallback();
                    })
                    .catch((error) => {
                        message.error(error.message || 'Signup Failed!');
                    }).finally(() => {
                        setIsLoading(false);
                    });
            } else if (userOption) {
                // For autodidacts and educators, store form data and redirect to checkout
                localStorage.setItem('signupFormData', JSON.stringify({ ...values, type: userOption.type }));
                navigate('/checkout', {
                    state: {
                        ...values,
                        type: userOption.type
                    }
                });
            }
        },
        validateOnChange: false,
        validateOnBlur: false
    });

    useEffect(() => {
        const fields = ['name', 'email', 'password', 'confirmPassword', 'educator_email'];
        fields.forEach(field => {
            const input = document.querySelector(`input[name="${field}"]`) as HTMLInputElement;
            if (input) {
                input.addEventListener('blur', () => {
                    formik.setFieldValue(field, input.value);
                });
            }
        });
    }, []);

    const checkEmailExists = async (email: string) => {
        if (email.includes('@') && email.includes('.')) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/email_exists/${email}`);
                const data = await response.json();
                setEmailExists(data.exists);
            } catch (error) {
                console.error('Error checking email:', error);
            }
        } else {
            setEmailExists(false);
        }
    };

    useEffect(() => {
        if (formik.values.email) {
            checkEmailExists(formik.values.email);
        }
    }, [formik.values.email]);

    const UserTypeCard = ({ option }: { option: UserTypeOption }) => (
        <Card
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid #333',
            }}
            bodyStyle={{
                padding: '8px',
                display: 'flex',
                flexDirection: 'column',
                flex: 1
            }}
        >
            <Title level={4} style={{
                marginBottom: '8px',
                textAlign: 'center'
            }}>
                {option.title}
            </Title>
            <Space direction="vertical" style={{ flexGrow: 1 }}>
            </Space>
            <ul style={{
                paddingLeft: '20px',
                margin: 0,
                flexGrow: 1,
            }}>
                {option.description.map((item, index) => (
                    <li key={index} style={{ marginBottom: '8px' }}><Text>{item}</Text></li>
                ))}
            </ul>
            <Button
                type="primary"
                onClick={() => setUserOption(option)}
                style={{
                    width: '100%',
                    marginTop: '8px',
                }}
            >
                {"Continue as " + option.title.toLowerCase()}
            </Button>
        </Card>
    );

    const handleChangeType = () => {
        setUserOption(null);
        formik.resetForm();
    };

    return (
        <Form
            onFinish={formik.handleSubmit}
            layout="vertical"
            style={{
                maxHeight: '80%',
                width: '100%',
                maxWidth: '1200px',
                margin: '0 auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            {!userOption ? (
                <>
                    <Title level={3} style={{ textAlign: 'center', marginBottom: '12px' }}>
                        You are signing up as:
                    </Title>
                    <Row gutter={[24, 24]} justify="space-between">
                        {userTypeOptions.map((option) => (
                            <Col key={option.title} xs={24} md={8}>
                                <UserTypeCard option={option} />
                            </Col>
                        ))}
                    </Row>
                </>
            ) : (
                <>
                    <Title level={2} style={{ marginBottom: '8px' }}>Sign up as {userOption.title}</Title>
                    <Space
                        direction="vertical"
                        size="small"
                        style={{
                            width: '100%',
                            maxWidth: '400px',
                        }}
                    >
                        <Form.Item
                            name="name"
                            validateStatus={formik.errors.name ? 'error' : ''}
                            help={formik.errors.name}
                            style={{ marginBottom: '16px' }}
                        >
                            <Input
                                size="large"
                                placeholder='Enter your name'
                                {...formik.getFieldProps('name')}
                                onBlur={(e) => {
                                    formik.handleBlur(e);
                                    formik.setFieldValue('name', e.target.value);
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            validateStatus={(formik.errors.email || emailExists) ? 'error' : ''}
                            help={formik.errors.email || (emailExists ? 'This email is already in use.' : '')}
                            style={{ marginBottom: '16px' }}
                        >
                            <Input
                                size="large"
                                placeholder='Enter your email'
                                {...formik.getFieldProps('email')}
                                onBlur={(e) => {
                                    formik.handleBlur(e);
                                    formik.setFieldValue('email', e.target.value);
                                    checkEmailExists(e.target.value);
                                }}
                                style={{
                                    borderColor: emailExists ? '#ff4d4f' : undefined,
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            validateStatus={formik.errors.password ? 'error' : ''}
                            help={formik.errors.password}
                            style={{ marginBottom: '16px' }}
                        >
                            <Input.Password
                                size="large"
                                placeholder='Enter your password'
                                {...formik.getFieldProps('password')}
                                onBlur={(e) => {
                                    formik.handleBlur(e);
                                    formik.setFieldValue('password', e.target.value);
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="confirmPassword"
                            validateStatus={formik.errors.confirmPassword ? 'error' : ''}
                            help={formik.errors.confirmPassword}
                            style={{ marginBottom: '16px' }}
                        >
                            <Input.Password
                                size="large"
                                placeholder='Confirm your password'
                                {...formik.getFieldProps('confirmPassword')}
                                onBlur={(e) => {
                                    formik.handleBlur(e);
                                    formik.setFieldValue('confirmPassword', e.target.value);
                                }}
                            />
                        </Form.Item>

                        {userOption.type === 'student' && (
                            <Form.Item
                                name="educator_email"
                                validateStatus={formik.errors.educator_email ? 'error' : ''}
                                help={formik.errors.educator_email}
                                style={{ marginBottom: '16px' }}
                            >
                                <Input
                                    size="large"
                                    placeholder='Enter the email of your educator'
                                    {...formik.getFieldProps('educator_email')}
                                    onBlur={(e) => {
                                        formik.handleBlur(e);
                                        formik.setFieldValue('educator_email', e.target.value);
                                    }}
                                />
                            </Form.Item>
                        )}

                        {userOption.type === 'professor' && (
                            <Form.Item
                                name="number_of_students"
                                validateStatus={formik.errors.number_of_students ? 'error' : ''}
                                help={formik.errors.number_of_students}
                                style={{ marginBottom: '16px' }}
                            >
                                <InputNumber
                                    size="large"
                                    min={0}
                                    placeholder='Number of students (you can start with 0)'
                                    style={{ width: '100%' }}
                                    value={formik.values.number_of_students}
                                    onChange={(value) => formik.setFieldValue('number_of_students', value)}
                                />
                            </Form.Item>
                        )}

                        {['professor', 'autodidact'].includes(userOption.type) && (
                            <Form.Item
                                name="number_of_messages"
                                validateStatus={formik.errors.number_of_messages ? 'error' : ''}
                                help={formik.errors.number_of_messages}
                                style={{ marginBottom: '16px' }}
                            >
                                <Select
                                    size="large"
                                    placeholder={userOption.type === 'autodidact' ? 'Number of messages' : 'Number of messages per student'}
                                    style={{ width: '100%' }}
                                    value={formik.values.number_of_messages}
                                    onChange={(value) => formik.setFieldValue('number_of_messages', value)}
                                >
                                    <Select.Option value={300}>300</Select.Option>
                                    <Select.Option value={500}>500</Select.Option>
                                </Select>
                            </Form.Item>
                        )}

                        <Form.Item style={{ marginBottom: '8px' }}>
                            <Button
                                loading={isLoading}
                                type="primary"
                                htmlType="submit"
                                size="large"
                                style={{ width: "100%" }}
                                disabled={emailExists}
                            >
                                Sign up
                            </Button>
                        </Form.Item>

                        <Form.Item style={{ marginBottom: '16px' }}>
                            <Button
                                onClick={handleChangeType}
                                size="large"
                                style={{ width: "100%" }}
                            >
                                Choose a different option
                            </Button>
                        </Form.Item>
                    </Space>
                </>
            )}
        </Form>
    );
};

const Signup: React.FC<SignupProps> = ({ loginCallback, isDarkMode, toggleTheme }) => {
    console.log("SignupScreen is rendering");
    
    return (
        <>
            <TopImages />
            <AbsoluteCenter>
                <Container>
                    <SignupFormContainer>
                        <Title
                            style={{ textAlign: 'center' }}
                        >Create an account
                        </Title>
                        <SignupForm loginCallback={loginCallback} />
                        <p className="mt-4 text-center">
                            Already have an account?{' '}
                            <Link to="/login" style={{ color: isDarkMode ? 'white' : 'black' }}>
                                Go to Login
                            </Link>
                        </p>
                    </SignupFormContainer>
                    <div className="absolute bottom-4 right-4 flex items-center">
                        <button
                            onClick={toggleTheme}
                            className={`p-2 rounded-full ${isDarkMode ? 'bg-black hover:bg-gray-800' : 'bg-white hover:bg-gray-200'
                                } transition-colors border-none`}
                            aria-label="Toggle theme"
                        >
                            {isDarkMode ? (
                                <SunOutlined style={{ fontSize: '24px', color: 'white' }} />
                            ) : (
                                <SunFilled style={{ fontSize: '24px', color: 'black' }} />
                            )}
                            <p className={`mr-2 ${isDarkMode ? 'text-white' : 'text-black'}`}>Switch theme</p>
                        </button>
                    </div>
                </Container>
            </AbsoluteCenter>
        </>
    );
};

export default Signup;