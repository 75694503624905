// api.ts
import { getUserDataFromLocalStorage } from "../utils/useLocalStorage";
import authedAxios from "./auth-axios";
import { BackendTemplate, Template } from "./templates";

export const analytics_chat_label = "analytics";

export interface Message {
    state_id: string;
    msg_id: string;
    user: "user" | "bot" | "system";
    text: string;
    sources: string[];
    timestamp: string;
}

export interface Conversation {
    _id: string;
    created_at: string;
    last_updated: string;
    name: string;
    type: string;
    user_id: string;
    course_id: string;
    messages: Message[];
    relevant_week_numbers: string[];
    templates: Template[];
}

export interface ConversationHistoryItem {
    _id: string;
    last_updated: string;
    course_name: string;
    course_id: string;
    name: string;
    type: string;
    number_of_messages: number;
}

export const loadConversationHistory = async (user_id: string): Promise<ConversationHistoryItem[]> => {
    try {
        const response = await authedAxios.get(`/conversations/history/${user_id}`);
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
};

export const newConversation = async (course_id: string, user_id: string, type: string): Promise<Conversation> => {
    try {
        const response = await authedAxios.post(`/conversations/new`, {
            course_id,
            user_id,
            type
        });
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
}


export const deleteConversation = async (conversation_id: string): Promise<Conversation> => {
    try {
        const response = await authedAxios.delete(`/conversations/${conversation_id}`);
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
}

export const getConversation = async (conversation_id: string): Promise<Conversation> => {
    try {
        const response = await authedAxios.get(`/conversations/${conversation_id}`);
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
}

export const likeMessage = async (conversation_id: string, botMsgContent: string): Promise<void> => {
    try {
        await authedAxios.patch(`/conversations/${conversation_id}/like-msg`, {
            msg_text: botMsgContent
        });
    } catch (error) {
        console.error('Error Liking Message:', error);
        throw error;
    }
}

export const dislikeMessage = async (conversation_id:string, botMsgContent: string): Promise<void> => {
    try {
        await authedAxios.patch(`/conversations/${conversation_id}/dislike-msg`, {
            msg_text: botMsgContent
        });
    } catch (error) {
        console.error('Error Liking Message:', error);
        throw error;
    }
}


export const updateConversationTemplates = async (conversationId: string, templates: BackendTemplate[]): Promise<boolean> => {
    try {
      const response = await authedAxios.put(`/conversations/${conversationId}/update_templates`, {
        templates: templates
      });
      return response.status === 200;
    } catch (error) {
      console.error('Error updating conversation templates:', error);
      return false;
    }
  }

export const getFileInConversation = async (conversation_id: string, file_id: string): Promise<any> => {
    try {
        const response = await authedAxios.get(
            `/conversations/${conversation_id}/file/${file_id}`,
            { responseType: 'blob' },
        );
        
        if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
    } catch (error) {
        console.error('Error fetching file:', error);
        throw error;
    }
}