import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import Placeholder from '@tiptap/extension-placeholder';
import HardBreak from '@tiptap/extension-hard-break';
import { Markdown } from 'tiptap-markdown';
import Link from '@tiptap/extension-link'
import { styled } from '@stitches/react';
import { Button, Tooltip, Space, Dropdown, Menu, theme } from 'antd';
import {
    BoldOutlined, ItalicOutlined, StrikethroughOutlined,
    HighlightOutlined, FontColorsOutlined, LinkOutlined,
    AlignLeftOutlined,
    AlignCenterOutlined,
    AlignRightOutlined
} from '@ant-design/icons';

const EditorContainer = styled('div', {
    border: '0px solid',
    padding: '4px 11px',
    minHeight: '32px',
    maxHeight: 'calc(20 * 1.5em)',
    marginTop: '8px',
    overflowY: 'auto',
});

const StyledEditorContent = styled(EditorContent, {
    '& .tiptap': {
        '& > *:first-child': { marginTop: 0 },
        '& ul, & ol': {
            padding: '0 1rem',
            margin: '1.25rem 1rem 1.25rem 0.4rem',
            '& li p': {
                marginTop: '0.25em',
                marginBottom: '0.25em',
            },
        },
        '& h1, & h2, & h3, & h4, & h5, & h6': {
            lineHeight: 1.5,
            marginTop: '2.5rem',
            textWrap: 'pretty',
        },
        '& h1, & h2': {
            marginTop: '3.5rem',
            marginBottom: '1.5rem',
        },
        '& h1': { fontSize: '1.4rem' },
        '& h2': { fontSize: '1.2rem' },
        '& h3': { fontSize: '1.1rem' },
        '& h4, & h5, & h6': { fontSize: '1rem' },
        '& p': {
            lineHeight: 1.5,
            marginBottom: '1em',
        },
        '& pre': {
            borderRadius: '0.5rem',
            color: '#fff',
            fontFamily: "'JetBrainsMono', monospace",
            margin: '1.5rem 0',
            padding: '0.75rem 1rem',
            '& code': {
                background: 'none',
                color: 'inherit',
                fontSize: '0.8rem',
                padding: 0,
            },
        },
        '& blockquote': {
            borderLeft: '3px solid #d9d9d9',
            margin: '1.5rem 0',
            paddingLeft: '1rem',
        },
        '& hr': {
            border: 'none',
            borderTop: '1px solid #d9d9d9',
            margin: '2rem 0',
        },
        '& p.is-editor-empty:first-child::before': {
            color: 'var(--gray-4)',
            content: 'attr(data-placeholder)',
            float: 'left',
            height: 0,
            pointerEvents: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
    },
});

const MenuBar = ({ editor }: { editor: any }) => {
    if (!editor) {
        return null;
    }

    const colorMenu = (
        <Menu>
            <Menu.Item key="1" onClick={() => editor.chain().focus().setColor('#958DF1').run()}>Purple</Menu.Item>
            <Menu.Item key="2" onClick={() => editor.chain().focus().setColor('#F98181').run()}>Red</Menu.Item>
            <Menu.Item key="3" onClick={() => editor.chain().focus().setColor('#FBBC88').run()}>Orange</Menu.Item>
            <Menu.Item key="4" onClick={() => editor.chain().focus().setColor('#FAF594').run()}>Yellow</Menu.Item>
            <Menu.Item key="5" onClick={() => editor.chain().focus().setColor('#70CFF8').run()}>Blue</Menu.Item>
            <Menu.Item key="6" onClick={() => editor.chain().focus().setColor('#94FADB').run()}>Teal</Menu.Item>
            <Menu.Item key="7" onClick={() => editor.chain().focus().setColor('#B9F18D').run()}>Green</Menu.Item>
        </Menu>
    );

    return (
        <Space>
            <Tooltip title="Bold">
                <Button icon={<BoldOutlined />} onClick={() => editor.chain().focus().toggleBold().run()} />
            </Tooltip>
            <Tooltip title="Italic">
                <Button icon={<ItalicOutlined />} onClick={() => editor.chain().focus().toggleItalic().run()} />
            </Tooltip>
            <Tooltip title="Strike">
                <Button icon={<StrikethroughOutlined />} onClick={() => editor.chain().focus().toggleStrike().run()} />
            </Tooltip>
            <Tooltip title="Highlight">
                <Button icon={<HighlightOutlined />} onClick={() => editor.chain().focus().toggleHighlight().run()} />
            </Tooltip>
            <Dropdown overlay={colorMenu} placement="bottomLeft" arrow>
                <Button icon={<FontColorsOutlined />} />
            </Dropdown>
            <Tooltip title="Link">
                <Button
                    icon={<LinkOutlined />}
                    onClick={() => {
                        const url = window.prompt('Enter the URL');
                        if (url) {
                            editor.chain().focus().setLink({ href: url }).run();
                        }
                    }}
                />
            </Tooltip>
            <Tooltip title="Align Left">
                <Button icon={<AlignLeftOutlined />} onClick={() => editor.chain().focus().setTextAlign('left').run()} />
            </Tooltip>
            <Tooltip title="Align Center">
                <Button icon={<AlignCenterOutlined />} onClick={() => editor.chain().focus().setTextAlign('center').run()} />
            </Tooltip>
            <Tooltip title="Align Right">
                <Button icon={<AlignRightOutlined />} onClick={() => editor.chain().focus().setTextAlign('right').run()} />
            </Tooltip>
        </Space>
    );
};

interface TiptapEditorProps {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    disabled?: boolean;
    rows?: number;
    streaming?: boolean;
    tiptap_testid?: string;
}

const TiptapEditor = forwardRef<any, TiptapEditorProps>(({ 
    value, 
    onChange, 
    placeholder, 
    disabled = false,
    rows = 10,
    streaming = false,
    tiptap_testid: testid = ''
}, ref) => {
    const { token } = theme.useToken();
    const [isEditable, setIsEditable] = useState(!disabled);
    const [localValue, setLocalValue] = useState(value);
    const isUpdatingRef = useRef(false);
    
    const editor = useEditor({
        extensions: [
            StarterKit,
            Markdown,
            Highlight,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            Color,
            TextStyle,
            Link.configure({
              openOnClick: false,
              autolink: true,
              defaultProtocol: 'https',
            }),
            Placeholder.configure({
                placeholder: placeholder
            }),
            HardBreak.configure({
                HTMLAttributes: {
                    class: 'custom-break',
                },
            }),
        ],
        content: localValue,
        onUpdate: ({ editor }) => {
            if (!streaming && !isUpdatingRef.current) {
                const newContent = editor.storage.markdown.getMarkdown();
                setLocalValue(newContent);
                onChange(newContent);
            }
        },
        editable: isEditable,
        parseOptions: {
          preserveWhitespace: 'full',
        },
        editorProps: {
          attributes: {
            testid: testid
          },
        },
    });

    useImperativeHandle(ref, () => ({
        editor
    }));

    useEffect(() => {
        if (editor && !streaming && value !== localValue) {
            isUpdatingRef.current = true;
            editor.commands.setContent(value);
            setLocalValue(value);
            isUpdatingRef.current = false;
        }
    }, [editor, value, streaming, localValue]);

    useEffect(() => {
        if (editor) {
            editor.setEditable(isEditable);
        }
    }, [isEditable, editor]);

    useEffect(() => {
        setIsEditable(!disabled);
    }, [disabled]);

    const focusEditor = useCallback(() => {
        if (editor && isEditable) {
            editor.chain().focus().run();
        }
    }, [editor, isEditable]);

    if (!editor) {
        return null;
    }

    const lineHeight = 1.5;
    const padding = 8;
    const minHeight = `${(rows * lineHeight) + padding}em`;

    return (
        <>
        <MenuBar editor={editor} />
        <EditorContainer 
            onClick={focusEditor}
            style={{ 
                background: token.colorBgContainer,
                opacity: disabled ? 0.5 : 1,
                cursor: disabled ? 'not-allowed' : 'text',
                minHeight: minHeight,
                maxHeight: `calc(${minHeight} * 2)`,
            }}
        >
            <StyledEditorContent editor={editor} />
        </EditorContainer>
        </>
    );
});

export default TiptapEditor;