import React, { useState, useMemo } from 'react';
import { Card, Typography, Select, Button, Space, theme } from 'antd';
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, ResponsiveContainer } from 'recharts';
import { BulbOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;
const { Option } = Select;

const modules = [
    {
        id: "M1",
        title: "Global Trade Theory",
        subtopics: ["Comparative", "Absolute", "Ricardian", "Heckscher-Ohlin", "Welfare", "Protectionism"]
    },
    {
        id: "M2",
        title: "Trade Policy Impact",
        subtopics: ["Tariffs", "Quotas", "Subsidies", "Sanctions", "Agreements", "Dumping"]
    },
    {
        id: "M3",
        title: "International Finance",
        subtopics: ["Forex", "Reserves", "Rates", "Investment", "Debt", "IMF"]
    },
    {
        id: "M4",
        title: "Regional Integration",
        subtopics: ["Blocs", "Tariff", "Market", "Union", "Rules", "Disputes"]
    },
    {
        id: "M5",
        title: "Emerging Economies",
        subtopics: ["Growth", "Investment", "Trade", "Debt", "Aid", "Innovation"]
    },
];

const students = ["Laura Fernandez", "Daniel Petersen", "Sofia Martinez", "Ethan Granger", "Maria Novak"];

const generateStudentData = (moduleId: string) => {
    const module = modules.find(m => m.id === moduleId);
    return module?.subtopics.map(subtopic => ({
        concept: subtopic,
        mastery: Math.random() * 4 + 1,
        previousMastery: Math.random() * 3 + 1
    })) || [];
};

const conceptMasteryData = Object.fromEntries(
    students.map(student => [
        student,
        Object.fromEntries(modules.map(module => [module.id, generateStudentData(module.id)]))
    ])
);

const targetMastery = 3.5;

export default function TopicUnderstanding() {
    const [selectedStudent, setSelectedStudent] = useState(students[0]);
    const [selectedView, setSelectedView] = useState<"current" | "progress" | "average">("current");
    const [selectedModule, setSelectedModule] = useState(modules[0].id);
    const { token } = theme.useToken();
    const navigate = useNavigate(); // Initialize the navigate function

    const currentModuleData = useMemo(() =>
        conceptMasteryData[selectedStudent][selectedModule],
        [selectedStudent, selectedModule]
    );

    return (
        <Card style={{ width: "100%", maxWidth: "800px", padding: "24px" }}>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                    <div>
                        <Title level={3} style={{ margin: 0 }}>Topic Understanding</Title>
                        <Text type="secondary">Overview of student performance across key course concepts</Text>
                    </div>
                    <Button
                        type="primary"
                        icon={
                            <Image src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Atlalogo-konnROAvzgfU5jeS25sJCyBXptiUG7.png" alt="Atla Logo" width={16} height={16} />
                        }
                        onClick={() => navigate(`/analytics-copilot/:chatID`)} // Update this line
                    >
                        Get help from Atla
                    </Button>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
                    <Select
                        style={{ width: "50%" }}
                        value={selectedModule}
                        onChange={setSelectedModule}
                    >
                        {modules.map((module) => (
                            <Option key={module.id} value={module.id}>
                                {module.id} | {module.title}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        style={{ width: "50%" }}
                        value={selectedStudent}
                        onChange={setSelectedStudent}
                    >
                        {students.map((student) => (
                            <Option key={student} value={student}>{student}</Option>
                        ))}
                    </Select>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
                    <Button
                        type={selectedView === "progress" ? "primary" : "default"}
                        onClick={() => setSelectedView(selectedView === "progress" ? "current" : "progress")}
                    >
                        Progress
                    </Button>
                    <Button
                        type={selectedView === "average" ? "primary" : "default"}
                        onClick={() => setSelectedView(selectedView === "average" ? "current" : "average")}
                    >
                        Average
                    </Button>
                </div>

                <div style={{ width: "100%", height: 400 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <RadarChart data={currentModuleData}>
                            <PolarGrid />
                            <PolarAngleAxis
                                dataKey="concept"
                                tick={({ x, y, payload }) => (
                                    <g transform={`translate(${x},${y})`}>
                                        <text x={0} y={0} dy={5} textAnchor="middle" fill={token.colorText} fontSize="12">
                                            {payload.value}
                                        </text>
                                    </g>
                                )}
                            />
                            <PolarRadiusAxis angle={30} domain={[0, 5]} />
                            <Radar
                                name="Current Mastery"
                                dataKey="mastery"
                                stroke="#36cfc9"
                                fill="#36cfc9"
                                fillOpacity={0.6}
                            />
                            {selectedView === "progress" && (
                                <Radar
                                    name="Previous Mastery"
                                    dataKey="previousMastery"
                                    stroke="#ff7a45"
                                    fill="#ff7a45"
                                    fillOpacity={0.4}
                                />
                            )}
                            {selectedView === "average" && (
                                <Radar
                                    name="Class Average"
                                    //   data={currentClassAverage}
                                    dataKey="mastery"
                                    stroke="#722ed1"
                                    fill="#722ed1"
                                    fillOpacity={0.4}
                                />
                            )}
                        </RadarChart>
                    </ResponsiveContainer>
                </div>

                <Space direction="vertical" size="small">
                    <Text>Mastery Scale: 1 (Low) - 5 (High)</Text>
                    <Space>
                        <ExclamationCircleOutlined style={{
                            color: "#ff4d4f"
                        }} />
                        <Text>Concepts with mastery levels below {targetMastery} require additional focus.</Text>
                    </Space>
                </Space>

                <Card style={{
                    // backgroundColor: "#fffbe6", 
                    border: "1px solid" // #ffe58f 
                }}>
                    <Space align="start">
                        <BulbOutlined style={{
                            fontSize: 24,
                            color: "#faad14"
                        }} />
                        <Text>
                            The class is performing well in most areas, but some concepts show below-average mastery (below 3.5). These topics require additional attention.
                        </Text>
                    </Space>
                </Card>

                <Card style={{
                    // backgroundColor: "#e6f7ff", 
                    border: "1px solid" //#91d5ff 
                }}>
                    <Space align="start">
                        <Image
                            src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Atlalogo-konnROAvzgfU5jeS25sJCyBXptiUG7.png"
                            alt="Atla Logo"
                            width={24}
                            height={24}
                        />
                        <Text>
                            Assign extra practice on concepts with lower mastery levels. Provide targeted quizzes and supplementary readings to strengthen understanding in these areas. Monitor progress weekly.
                        </Text>
                    </Space>
                </Card>
            </Space>
        </Card>
    );
}