import React, { useState, useMemo } from 'react';
import { Treemap, ResponsiveContainer, Tooltip } from 'recharts';
import { Card, Select, Button, Badge, Typography, Space, Image } from 'antd';
import { BulbOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;
const { Option } = Select;

interface Module {
  id: string;
  name: string;
}

interface Student {
  id: string;
  name: string;
}

interface MaterialData {
  [x: string]: any;
  name: string;
  engagement_type: 'engaged' | 'underutilized' | 'challenging';
  fileType: string;
  useCase: string;
  level: string;
  participation: number;
  completionRate: string;
  revisits: number;
}

interface TreemapContentProps {
    root?: MaterialData;
    depth?: number;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    index?: number;
    payload?: MaterialData;
    rank?: number;
    name?: string;
    engagement_type?: string;
  }


  const TreemapContent: React.FC<TreemapContentProps> = ({ 
    root, depth, x, y, width, height, index, name 
  }) => {
    if (!root || depth === undefined || x === undefined || y === undefined || 
        width === undefined || height === undefined || index === undefined) {
      return null;
    }
    
    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: colorScale[root.children[index].engagement_type],
            // stroke: '#fff',
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10),
          }}
        />
        {depth === 1 && (
          <text
            x={x + width / 2}
            y={y + height / 2 + 7}
            textAnchor="middle"
            // fill="#fff"
            fontSize={14}
          >
            {name}
          </text>
        )}
      </g>
    );
  };

const modules: Module[] = [
  { id: "M1", name: "M1 | Global Trade Theory" },
  { id: "M2", name: "M2 | Trade Policy Impact" },
  { id: "M3", name: "M3 | International Finance" },
  { id: "M4", name: "M4 | Regional Integration" },
  { id: "M5", name: "M5 | Emerging Economies" },
];

const students: Student[] = [
  { id: "student1", name: "Laura Fernandez" },
  { id: "student2", name: "Daniel Petersen" },
  { id: "student3", name: "Sofia Martinez" },
  { id: "student4", name: "Ethan Granger" },
  { id: "student5", name: "Maria Novak" },
];

const generateModuleData = (): MaterialData[] => [
  {
    name: "Trade Blocs Lecture",
    engagement_type: "engaged",
    fileType: "video",
    useCase: "lecture",
    level: "intermediate",
    participation: 89,
    completionRate: "85%",
    revisits: 20,
  },
  {
    name: "Market Union Paper",
    engagement_type: "underutilized",
    fileType: "pdf",
    useCase: "supplementary",
    level: "advanced",
    participation: 30,
    completionRate: "40%",
    revisits: 5,
  },
  {
    name: "Tariff Union Slides",
    engagement_type: "challenging",
    fileType: "presentation",
    useCase: "core",
    level: "intermediate",
    participation: 65,
    completionRate: "60%",
    revisits: 45,
  },
  {
    name: "Integration Debate",
    engagement_type: "engaged",
    fileType: "task",
    useCase: "practice",
    level: "advanced",
    participation: 78,
    completionRate: "90%",
    revisits: 15,
  },
  {
    name: "Customs Union Analysis",
    engagement_type: "challenging",
    fileType: "pdf",
    useCase: "exam prep",
    level: "advanced",
    participation: 55,
    completionRate: "70%",
    revisits: 40,
  },
  {
    name: "FTA Podcast",
    engagement_type: "underutilized",
    fileType: "audio",
    useCase: "supplementary",
    level: "beginner",
    participation: 25,
    completionRate: "35%",
    revisits: 3,
  },
  {
    name: "Dispute Resolution Exercise",
    engagement_type: "engaged",
    fileType: "task",
    useCase: "practice",
    level: "intermediate",
    participation: 82,
    completionRate: "88%",
    revisits: 18,
  }
]

const generateStudentData = (): { [key: string]: { [key: string]: MaterialData[] } } => {
    const data: { [key: string]: { [key: string]: MaterialData[] } } = {};
    students.forEach(student => {
      data[student.id] = {};
      modules.forEach(module => {
        data[student.id][module.id] = generateModuleData().map(item => ({
          ...item,
          participation: 
            item.engagement_type === "engaged" ? Math.floor(Math.random() * 21) + 80 :
            item.engagement_type === "challenging" ? Math.floor(Math.random() * 21) + 50 :
            Math.floor(Math.random() * 21) + 20,
          completionRate: 
            item.engagement_type === "engaged" ? `${Math.floor(Math.random() * 16) + 85}%` :
            item.engagement_type === "challenging" ? `${Math.floor(Math.random() * 21) + 60}%` :
            `${Math.floor(Math.random() * 21) + 30}%`,
          revisits: 
            item.engagement_type === "engaged" ? Math.floor(Math.random() * 11) + 15 :
            item.engagement_type === "challenging" ? Math.floor(Math.random() * 21) + 30 :
            Math.floor(Math.random() * 6) + 1,
        }));
      });
    });
    return data;
  };
  
  const studentData = generateStudentData();
  
  const calculateAverageData = (moduleId: string): MaterialData[] => {
    return generateModuleData().map(material => {
      const participations = students.map(student => 
        studentData[student.id][moduleId].find(item => item.name === material.name)?.participation || 0
      );
      const completionRates = students.map(student => 
        parseInt(studentData[student.id][moduleId].find(item => item.name === material.name)?.completionRate || "0")
      );
      const revisitsCounts = students.map(student => 
        studentData[student.id][moduleId].find(item => item.name === material.name)?.revisits || 0
      );
      
      return {
        ...material,
        participation: Math.round(participations.reduce((a, b) => a + b, 0) / participations.length),
        completionRate: `${Math.round(completionRates.reduce((a, b) => a + b, 0) / completionRates.length)}%`,
        revisits: Math.round(revisitsCounts.reduce((a, b) => a + b, 0) / revisitsCounts.length),
      };
    });
  };
  
  const colorScale: { [key: string]: string } = {
    engaged: "#52c41a",
    challenging: "#faad14",
    underutilized: "#ff4d4f"
  };
  
  interface CustomTooltipProps {
    active?: boolean;
    payload?: Array<{ payload: MaterialData }>;
  }
  
  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Card size="small" title={data.name} style={{ width: 300 }}>
          <Space direction="vertical">
            <Space>
              <Badge color={colorScale[data.engagement_type]} text={data.fileType} />
              <Badge color={colorScale[data.engagement_type]} text={data.useCase} />
              <Badge color={colorScale[data.engagement_type]} text={data.level} />
            </Space>
            <Text>Participation: {data.participation}</Text>
            <Text>Completion Rate: {data.completionRate}</Text>
            <Text>Revisits: {data.revisits}</Text>
          </Space>
        </Card>
      );
    }
    return null;
  };

const CourseMaterialReport: React.FC = () => {
const [selectedStudent, setSelectedStudent] = useState<string>(students[0].id);
const [selectedModule, setSelectedModule] = useState<string>(modules[0].id);
const [showAverage, setShowAverage] = useState<boolean>(false);
const navigate = useNavigate(); // Initialize the navigate function

const displayData = useMemo(() => {
    if (showAverage) return calculateAverageData(selectedModule);
    return selectedStudent ? studentData[selectedStudent][selectedModule] || [] : [];
}, [selectedStudent, selectedModule, showAverage]);

const handleStudentChange = (value: string) => {
    setSelectedStudent(value);
    setShowAverage(false);
};

const toggleAverage = () => {
    setShowAverage((prev) => !prev);
};
  

return (
    <Card style={{ width: '100%', maxWidth: '800px', margin: '0 auto' }}>
      <Card
        title={
          <Space direction="vertical" size="small" style={{ marginTop: '16px' }}>
            <Title level={4} style={{ marginBottom: '4px' }}>Course Material Engagement</Title>
            <Text type="secondary">Student interaction with course materials</Text>
          </Space>
        }
        extra={
            <Button
            type="primary"
            icon={
                <Image src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Atlalogo-konnROAvzgfU5jeS25sJCyBXptiUG7.png" alt="Atla Logo" width={16} height={16} />
            }
            onClick={() => navigate(`/analytics-copilot/:chatID`)} // Update this line
        >
            Get help from Atla
        </Button>
        }
        style={{ width: '100%' }}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Space wrap>
            <Select
              style={{ width: 200 }}
              placeholder="Select student"
              onChange={handleStudentChange}
              value={showAverage ? undefined : selectedStudent}
              disabled={showAverage}
            >
              {students.map((student) => (
                <Option key={student.id} value={student.id}>
                  {student.name}
                </Option>
              ))}
            </Select>
            <Select
              style={{ width: 250 }}
              placeholder="Select a module"
              onChange={setSelectedModule}
              value={selectedModule}
            >
              {modules.map((module) => (
                <Option key={module.id} value={module.id}>
                  {module.name}
                </Option>
              ))}
            </Select>
            <Button
              type={showAverage ? "primary" : "default"}
              onClick={toggleAverage}
            >
              {showAverage ? "Showing Average" : "Show Average"}
            </Button>
          </Space>
      <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer width="100%" height="100%">
          <Treemap
            data={displayData}
            dataKey="participation"
            aspectRatio={4 / 3}
            stroke="#fff"
            // fill="#8884d8"
            content={<TreemapContent />}
            // content={({ root, depth, x, y, width, height, index, payload, colors, rank, name }) => {
            //     return (
            //       <g>
            //         <rect
            //           x={x}
            //           y={y}
            //           width={width}
            //           height={height}
            //           style={{
            //             fill: colorScale[displayData[index].type],
            //             stroke: '#fff',
            //             strokeWidth: 2 / (depth + 1e-10),
            //             strokeOpacity: 1 / (depth + 1e-10),
            //           }}
            //         />
            //         {depth === 1 && (
            //           <text
            //             x={x + width / 2}
            //             y={y + height / 2 + 7}
            //             textAnchor="middle"
            //             fill="#fff"
            //             fontSize={14}
            //           >
            //             {name}
            //           </text>
            //         )}
            //       </g>
            //     )
            //     }}
          >
            <Tooltip content={<CustomTooltip />} />
          </Treemap>
        </ResponsiveContainer>
      </div>
          <Space wrap>
            {Object.entries(colorScale).map(([type, color]) => (
              <Space key={type}>
                <Badge color={color} text={type.charAt(0).toUpperCase() + type.slice(1)} />
              </Space>
            ))}
          </Space>
          <Space direction="vertical" size="large" style={{ width: '100%', maxWidth: '800px', margin: '0 auto' }}>
            <Card
              style={{ 
                // backgroundColor: '#FFF9E6', 
                border: "1px solid" 
            }}
              bodyStyle={{ padding: '16px' }}
            >
              <Space align="start">
                <BulbOutlined style={{ fontSize: '24px', color: '#FFCC00' }} />
                <Text>
                  "Market Union Paper" and "FTA Podcast" are under-utilised and challenging for students, with low completion rates and multiple revisits, indicating potential confusion or difficulty.
                </Text>
              </Space>
            </Card>

            <Card
              style={{ 
                // backgroundColor: '#E6F7FF', 
                border: "1px solid"
            }}
              bodyStyle={{ padding: '16px' }}
            >
              <Space align="start">
                <Image
                  src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Atlalogo-BA8e9f3sILkwk4vYGI37kKXPpM7odu.png"
                  alt="Atlas Logo"
                  width={40}
                  preview={false}
                />
                <Space direction="vertical">
                  <Text>
                    Provide additional explanations or create a prompt template for these resources, focusing on simplifying key concepts.
                  </Text>
                </Space>
              </Space>
            </Card>
          </Space>
        </Space>
      </Card>
    </Card>
  )
}


export default CourseMaterialReport;