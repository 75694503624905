import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
import { styled } from "@stitches/react";
import { Avatar, Button, message, theme } from "antd";
import 'katex/dist/katex.min.css'; // Import KaTeX CSS
import { useEffect, useState } from "react";

import MarkdownView, { ShowdownExtension } from "react-showdown";
// @ts-ignore
import showdownKatex from 'showdown-katex';
import { dislikeMessage, likeMessage } from "../../services/conversations";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";
import { preprocessLatex } from "../../utils/utils";
import { atla_logo_path, chatFormatContent, sona_logo_path } from "../../config";
import SourceButton, { SourceItem } from "./SourceButton";


const MessageBubbleContainer = styled("div", {
    maxWidth: '100%', // Ensure the bubble doesn't exceed the container width
    flexShrink: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "10px",
    padding: "10px",

    "& #answer > :first-child ": {
        marginTop: "0 !important",
        paddingTop: "0 !important",
    },
});


const TextContentContainer = styled("div", {
    width: '100%',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    '& .markdown-body': {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        '& > :first-child': {
            marginTop: '0 !important',
            paddingTop: '0 !important',
        },
        '& > :last-child': {
            marginBottom: '0 !important',
            paddingBottom: '0 !important',
        },
    },
});

const LikeDislikeContainer = styled("div", {
    display: "flex",
    flexDirection: "row",
    gap: "0.5rem"
});

const Flex = styled("div", {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem"
})


export const MessageBubble = ({
    conversationId,
    msgId,
    user,
    text,
    sources,
    type }: {
        conversationId: string,
        msgId: string,
        user: "user" | "bot" | "system",
        text: string,
        sources: string[],
        type: string
    }) => {
        const { token } = theme.useToken();
        const userData = getUserDataFromLocalStorage();
        const [logoData, setLogoData] = useState<string | undefined>(undefined);
        const [mainText, setMainText] = useState<string>('');
        const [sourceItems, setSourceItems] = useState<SourceItem[]>([]);
        const [likeLoading, setLikeLoading] = useState<boolean>(false);
        const [dislikeLoading, setDislikeLoading] = useState<boolean>(false);
    
        useEffect(() => {
            // Split the text into main content and source items
            const parts = text.split('<source_button>');
            const mainContent = parts[0];
            const sourcesItems: SourceItem[] = [];
            
            // Process each source button part after the first split
            if (parts.length > 1) {
                parts.slice(1).forEach(part => {
                    const endIndex = part.indexOf('</source_button>');
                    if (endIndex !== -1) {
                        const sourceContent = part.substring(0, endIndex);
                        const [fileId, sourceText] = sourceContent.split(':');
                        sourcesItems.push({
                            fileId: fileId.trim(),
                            sourceText: sourceText.trim()
                        });
                    }
                });
            } else {
                // set sources as input attribute sources if no empty
                if (sources.length > 0) {
                    sources.forEach(source => {
                        source = source.replace('<source_button>', '');
                        source = source.replace('</source_button>', '');
                        const [fileId, sourceText] = source.split(':');
                        sourcesItems.push({
                            fileId: fileId.trim(),
                            sourceText: sourceText.trim()
                        });
                    });
                }
            }
    
            setMainText(preprocessLatex(mainContent));
            setSourceItems(sourcesItems);
        }, [text, sources]);

    useEffect(() => {
        setLogoData(userData.config.logo);
    }, [user, userData.config.logo]);

    function handleLike() {
        setLikeLoading(true);
        likeMessage(conversationId, text)
            .then(() => {
                message.success("Thank you for the feedback!");
            })
            .catch(err => {
                message.error("Error liking message: " + err);
                console.error(err);
            })
            .finally(() => setLikeLoading(false));
    }

    function handleDislike() {
        setDislikeLoading(true);
        dislikeMessage(conversationId, text)
            .then(() => {
                message.success("Thank you for the feedback!");
            })
            .catch(err => {
                message.error("Error disliking message: " + err);
                console.error(err);
            })
            .finally(() => setDislikeLoading(false));
    }

    return (
        <MessageBubbleContainer>
            <Avatar
                size="small"
                src={(user === "bot" || user === "system") && (type === chatFormatContent ? sona_logo_path : atla_logo_path)}
                style={{
                    flexShrink: 0,
                    alignSelf: 'flex-start',
                    marginTop: "-3px",
                    backgroundColor: user === "user" ? token.colorBgSpotlight : "transparent"
                }}
            >
                {user === "user" && userData.name[0]}
            </Avatar>
            <Flex>
                <b>{user === "user" ? "You" : (type === chatFormatContent ? "Sona" : "Atla")}</b>
                <TextContentContainer>
                    <MarkdownView
                        markdown={mainText}
                        options={{ tables: true, emoji: true }}
                        extensions={showdownKatex({
                            displayMode: true,
                            throwOnError: false,
                            errorColor: '#ff0000',
                            delimiters: [
                                { left: "$$", right: "$$", display: true },
                                { left: "\\[", right: "\\)", display: true },
                                { left: "\\(", right: "\\)", display: true },
                                { left: "$", right: "$", display: false }
                            ]
                        }) as unknown as ShowdownExtension[]}
                    />
                    {sourceItems.map((item, index) => (
                        <SourceButton
                            key={index}
                            conversationId={conversationId}
                            fileId={item.fileId}
                            sourceText={item.sourceText}
                        />
                    ))}
                </TextContentContainer>
                {user === "bot" && (
                    <LikeDislikeContainer>
                        <Button loading={likeLoading} type="text" icon={<LikeOutlined />} onClick={handleLike} />
                        <Button loading={dislikeLoading} type="text" icon={<DislikeOutlined />} onClick={handleDislike} />
                    </LikeDislikeContainer>
                )}
            </Flex>
        </MessageBubbleContainer>
    );
};