import { message } from 'antd';

export function setupGlobalErrorHandlers() {
  window.addEventListener('unhandledrejection', (event) => {
    event.preventDefault(); // This prevents the error from being logged to the console
    console.error('Unhandled promise rejection:', event.reason);
    message.error('An unexpected error occurred. Please try again.');
  });

  window.addEventListener('error', (event) => {
    event.preventDefault(); // This prevents the error from being logged to the console
    console.error('Global error:', event.error);
    message.error('An unexpected error occurred. Please try again.');
  });
}