import React, { useState } from "react"
import { Card, Select, Typography, Button, Space, Image } from "antd"
import { Bar, Line, ComposedChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts"
import { BulbOutlined } from '@ant-design/icons'
import { useNavigate } from "react-router-dom"

const { Title, Paragraph, Text } = Typography
const { Option } = Select

// Populated data for all students with more normal numbers for Daniel and Sofia
const studentsData = {
    "Laura Fernandez": [
        { week: "Week 1", videos: 2, readings: 3, quizzes: 1, engagement: 60 },
        { week: "Week 2", videos: 3, readings: 2, quizzes: 2, engagement: 70 },
        { week: "Week 3", videos: 1, readings: 4, quizzes: 1, engagement: 55 },
        { week: "Week 4", videos: 4, readings: 1, quizzes: 3, engagement: 85 },
        { week: "Week 5", videos: 2, readings: 3, quizzes: 2, engagement: 75 },
    ],
    "Daniel Petersen": [
        { week: "Week 1", videos: 2, readings: 3, quizzes: 1, engagement: 65 },
        { week: "Week 2", videos: 3, readings: 2, quizzes: 2, engagement: 70 },
        { week: "Week 3", videos: 2, readings: 3, quizzes: 2, engagement: 75 },
        { week: "Week 4", videos: 3, readings: 2, quizzes: 1, engagement: 68 },
        { week: "Week 5", videos: 2, readings: 4, quizzes: 2, engagement: 78 },
    ],
    "Sofia Martinez": [
        { week: "Week 1", videos: 3, readings: 2, quizzes: 2, engagement: 70 },
        { week: "Week 2", videos: 2, readings: 3, quizzes: 1, engagement: 65 },
        { week: "Week 3", videos: 3, readings: 3, quizzes: 2, engagement: 75 },
        { week: "Week 4", videos: 2, readings: 4, quizzes: 1, engagement: 72 },
        { week: "Week 5", videos: 3, readings: 2, quizzes: 3, engagement: 80 },
    ],
    "Ethan Granger": [
        { week: "Week 1", videos: 4, readings: 1, quizzes: 3, engagement: 75 },
        { week: "Week 2", videos: 2, readings: 3, quizzes: 2, engagement: 70 },
        { week: "Week 3", videos: 3, readings: 2, quizzes: 1, engagement: 65 },
        { week: "Week 4", videos: 1, readings: 4, quizzes: 2, engagement: 60 },
        { week: "Week 5", videos: 4, readings: 2, quizzes: 3, engagement: 90 },
    ],
    "Maria Novak": [
        { week: "Week 1", videos: 2, readings: 3, quizzes: 2, engagement: 65 },
        { week: "Week 2", videos: 3, readings: 2, quizzes: 3, engagement: 80 },
        { week: "Week 3", videos: 1, readings: 4, quizzes: 1, engagement: 55 },
        { week: "Week 4", videos: 4, readings: 1, quizzes: 2, engagement: 75 },
        { week: "Week 5", videos: 3, readings: 3, quizzes: 3, engagement: 85 },
    ],
}
type StudentKeys = 'videos' | 'readings' | 'quizzes' | 'engagement';

// Calculate average
const averageData = Object.values(studentsData)[0].map((week: any, index: any) => {
    const avgWeek = { ...week }
    Object.keys(week).forEach(key => {
        if (key !== 'week' && (key as StudentKeys)) {
            avgWeek[key] = Object.values(studentsData).reduce((sum, student) => sum + student[index][key as StudentKeys], 0) / Object.keys(studentsData).length
        }
    })
    return avgWeek
})

export default function Engagement() {
    const [selectedStudent, setSelectedStudent] = useState<string | null>("Laura Fernandez")
    const [showAverage, setShowAverage] = useState(false)
    const navigate = useNavigate(); // Initialize the navigate function

    const data = showAverage ? averageData : (selectedStudent ? studentsData[selectedStudent as keyof typeof studentsData] : [])

    const toggleAverage = () => {
        setShowAverage(!showAverage)
        if (!showAverage) {
            setSelectedStudent(null)
        }
    }

    return (
        <Card style={{ width: '100%', maxWidth: '800px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <Title level={4} style={{ margin: 0 }}>Engagement Levels</Title>
                <Button
                        type="primary"
                        icon={
                            <Image src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Atlalogo-konnROAvzgfU5jeS25sJCyBXptiUG7.png" alt="Atla Logo" width={16} height={16} />
                        }
                        onClick={() => navigate(`/analytics-copilot/:chatID`)} // Update this line
                    >
                        Get help from Atla
                    </Button>
            </div>
            <Paragraph>Weekly engagement trends across videos, readings, quizzes, and overall participation.</Paragraph>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <Select
                    style={{ width: 180 }}
                    value={selectedStudent}
                    onChange={setSelectedStudent}
                    disabled={showAverage}
                    placeholder="Select student"
                >
                    {Object.keys(studentsData).map(student => (
                        <Option key={student} value={student}>{student}</Option>
                    ))}
                </Select>
                <Button onClick={toggleAverage} type={showAverage ? "primary" : "default"}>
                    Average
                </Button>
            </div>
            <div style={{ height: '400px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart data={data} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="week" />
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" orientation="right" />
                        <Tooltip />
                        <Legend />
                        <Bar yAxisId="left" dataKey="videos" stackId="a" fill="#1890ff" name="Videos" />
                        <Bar yAxisId="left" dataKey="readings" stackId="a" fill="#13c2c2" name="Readings" />
                        <Bar yAxisId="left" dataKey="quizzes" stackId="a" fill="#52c41a" name="Quizzes" />
                        <Line yAxisId="right" type="monotone" dataKey="engagement" stroke="#722ed1" name="Engagement" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>

            <Space direction="vertical" size="large" style={{ width: '100%', marginTop: '20px' }}>
                <Card
                    style={{
                        // backgroundColor: '#FFF9E6', 
                        border: "1px solid" //#91d5ff 
                    }}
                    bodyStyle={{ padding: '16px' }}
                >
                    <Space align="start">
                        <BulbOutlined style={{
                            fontSize: '24px',
                            color: '#FFCC00' 
                        }} />
                        <Text>
                            Engagement peaked during Week 5, suggesting increased interaction before deadlines. Video content remains underutilised compared to readings and quizzes throughout the course, indicating a need for rebalancing.
                        </Text>
                    </Space>
                </Card>

                <Card
                    style={{
                        // backgroundColor: '#E6F7FF', 
                        border: "1px solid" //#91d5ff 
                    }}
                    bodyStyle={{ padding: '16px' }}
                >
                    <Space align="start">
                        <Image
                            src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Atlalogo-BA8e9f3sILkwk4vYGI37kKXPpM7odu.png"
                            alt="Atlas Logo"
                            width={36}
                            preview={false}
                        />
                        <Space direction="vertical">
                            <Text>
                                Recommend additional videos on key topics in International Economics, such as trade policies and global market dynamics. This can enhance student understanding and increase engagement with video materials next week.
                            </Text>
                        </Space>
                    </Space>
                </Card>
            </Space>
        </Card>
    )
}