import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Spin, Row, Col, Table, Typography, Select, DatePicker, theme, Divider, Modal } from 'antd';
import { Bar, Scatter } from 'react-chartjs-2';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartData,
    ChartOptions,
    PointElement
} from 'chart.js';
import {
    AvgMessagePerDayPlotResponse,
    AvgMessagePerDayTableDataType,
    QueryPCADataPoint,
    fetchAvgMessagePerDayData,
    fetchCoverageData,
    fetchSatisfactionData,
    fetchSourceCountData,
    getQueryData,
    QueryData,
    RagCoveragePlotResponse,
    RagCoverageTableDataType,
    SatisfactionPlotResponse,
    SatisfactionTableDataType,
    SourceCountPlotResponse,
    SourceCountTableDataType,
    QueryPCAData
} from '../../services/analytics';
import { Content } from 'antd/es/layout/layout';
import { PCA } from 'ml-pca';
import { CourseContext } from '../courses/course';
import { useCourse } from '../../hooks/useCourse';
import { FlexMarginButton } from '../../components/basic/buttons';
import { capitalize } from '../../utils/utils';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';
import CourseReportsPage from './CourseReportsPage';

const { RangePicker } = DatePicker;
const { Option } = Select;

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement
);


interface EmbeddingScatterPlotProps {
    data: ChartData<'scatter'>;
    options?: ChartOptions<'scatter'>;
}

const EmbeddingScatterPlot: React.FC<EmbeddingScatterPlotProps> = ({ data, options }) => {
    const defaultOptions: ChartOptions<'scatter'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
            },
        },
        scales: {
            x: {
                display: true,
                border: {
                    display: true
                },
                ticks: {
                    display: false,
                },
                beginAtZero: true,
            },
            y: {
                display: true,
                border: {
                    display: true
                },
                ticks: {
                    display: false,
                },
                beginAtZero: true,
            },
        },
    };

    const mergedOptions = { ...defaultOptions, ...options };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Scatter data={data} options={mergedOptions} />
        </div>
    );
};


export const CourseAnalytics: React.FC = () => {
    const courseHook = useCourse()
    const { tempCourse, setTempCourse, hasChanges } = courseHook
    const [isLoading, setIsLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [chartData, setChartData] = useState<{
        satisfaction: SatisfactionPlotResponse[];
        ragCoverage: RagCoveragePlotResponse[];
        avgMessagePerDay: AvgMessagePerDayPlotResponse[];
        sourceCounts: SourceCountPlotResponse[];
        queryData: QueryData;
        queryPCAData: QueryPCAData;
    }>({
        satisfaction: [],
        ragCoverage: [],
        avgMessagePerDay: [],
        sourceCounts: [],
        queryData: { queries: [], topics: [] },
        queryPCAData: { queriesPCA: [], topicsPCA: [] }
    });
    const [selectedUsers, setSelectedUsers] = useState<{
        satisfaction: string[];
        ragCoverage: string[];
        avgMessagePerDay: string[];
        sourceCounts: string[];
    }>({
        satisfaction: [],
        ragCoverage: [],
        avgMessagePerDay: [],
        sourceCounts: []
    });
    const [selectedWeeks, setSelectedWeeks] = useState<number[]>([]);
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(null);
    const { token } = theme.useToken();
    const user = getUserDataFromLocalStorage();
    const [isReportsModalVisible, setIsReportsModalVisible] = useState(false);

    const tableHeaderHeight = 39;
    const [tableHeight80] = useState(() => {
        // Set default to 80vh - 55px
        return Math.floor(window.innerHeight * 0.8) - tableHeaderHeight;
    });

    const [tableHeight50] = useState(() => {
        // Set default to 80vh - 55px
        return Math.floor(window.innerHeight * 0.5) - tableHeaderHeight;
    });

    const loadData = useCallback(async () => {
        if (selectedWeeks.length > 0 && dateRange !== null && tempCourse) {
            const relevant_weeks = selectedWeeks.map(weekNum => tempCourse.weeks[weekNum - 1].number.toString());
            const [start, end] = dateRange;
            const timeframe = [`${start.toISOString()}`, `${end.toISOString()}`];
            setIsLoading(true);
            try {
                const student_ids = tempCourse.enrolled_students.map(student => student._id);

                const [
                    satisfaction_data,
                    rag_coverage_data,
                    avg_messages_per_day_data,
                    source_count_data,
                    query_data
                ] = await Promise.all([
                    fetchSatisfactionData(tempCourse._id, timeframe, student_ids),
                    fetchCoverageData(tempCourse._id, timeframe, relevant_weeks, student_ids),
                    fetchAvgMessagePerDayData(tempCourse._id, student_ids),
                    fetchSourceCountData(tempCourse._id, timeframe, student_ids),
                    getQueryData(tempCourse._id, timeframe),
                ]);

                setChartData({
                    satisfaction: satisfaction_data,
                    ragCoverage: rag_coverage_data,
                    avgMessagePerDay: avg_messages_per_day_data,
                    sourceCounts: source_count_data,
                    queryData: query_data,
                    queryPCAData: toCombinedPca(query_data.queries, query_data.topics, 2)
                });

                setSelectedUsers({
                    satisfaction: satisfaction_data.filter(item => typeof item.satisfaction === 'number').map(item => item.user_id),
                    ragCoverage: rag_coverage_data.filter(item => typeof item.coverage === 'number').map(item => item.user_id),
                    avgMessagePerDay: avg_messages_per_day_data.filter(item => typeof item.avg_messages_per_day === 'number').map(item => item.user_id),
                    sourceCounts: source_count_data.map(item => item.user_id)
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
                setHasLoaded(true);
            }
        }
    }, [selectedWeeks, dateRange, tempCourse]);

    useEffect(() => {
        loadData();
    }, [loadData]);


    const handleWeekChange = (values: number[]) => {
        setSelectedWeeks(values)
    };

    const handleDateRangeChange = (dates: [Dayjs, Dayjs] | null) => {
        setDateRange(dates);
    };

    const handleOpenReports = () => {
        setIsReportsModalVisible(true);
    };

    const handleCloseReports = () => {
        setIsReportsModalVisible(false);
    };

    const getSelectedDates = useMemo(() => {
        if (selectedWeeks.length > 0 && dateRange !== null && tempCourse) {
            const selectedWeekObjects = selectedWeeks.map(weekNum => tempCourse.weeks[weekNum - 1]);
            const startDate = selectedWeekObjects.reduce((min, week) =>
                dayjs(week.start_date).isBefore(min) ? dayjs(week.start_date) : min,
                dayjs(selectedWeekObjects[0].start_date)
            );
            const endDate = selectedWeekObjects.reduce((max, week) =>
                dayjs(week.end_date).isAfter(max) ? dayjs(week.end_date) : max,
                dayjs(selectedWeekObjects[0].end_date)
            );
            return `Selected analytics for interactions between ${startDate.format('YYYY-MM-DD')} and ${endDate.format('YYYY-MM-DD')} with materials of weeks: ${selectedWeeks.join(', ')}.`;
        }
        return 'Please select a date range and one or multiple weeks to view analytics.';
    }, [selectedWeeks, dateRange, tempCourse]);


    function toCombinedPca(queries: QueryPCADataPoint[], topics: QueryPCADataPoint[], dimensions: number): { queriesPCA: number[][], topicsPCA: number[][] } {
        // Extract embeddings and combine them
        const queriesEmbeddings = queries.map(q => q.embedding);
        const topicsEmbeddings = topics.map(t => t.embedding);
        const combinedData = [...queriesEmbeddings, ...topicsEmbeddings];

        // Check if the combined data is valid
        if (!combinedData || combinedData.length === 0 || combinedData.length === 1 || combinedData.some(row => row.length === 0)) {
            return { queriesPCA: [[]], topicsPCA: [[]] };
        }

        // Perform PCA on the combined data
        const pca = new PCA(combinedData);
        const transformed = pca.predict(combinedData, { nComponents: dimensions });

        // Split the transformed data back into queries and topics
        const transformedArray = transformed.to2DArray();
        const queriesPCA = transformedArray.slice(0, queries.length);
        const topicsPCA = transformedArray.slice(queries.length);

        return { queriesPCA, topicsPCA };
    }

    const pcaData: ChartData<'scatter'> = {
        datasets: [
            {
                label: 'Queries',
                data: chartData.queryPCAData.queriesPCA.map((d, i) => ({ x: d[0], y: d[1], id: i })),
                backgroundColor: '#27aeef',
            },
            {
                label: 'Topics',
                data: chartData.queryPCAData.topicsPCA.map((d, i) => ({ x: d[0], y: d[1], id: i })),
                backgroundColor: '#ea5545',
            },
        ],
    };

    const pcaOptions: ChartOptions<'scatter'> = {
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Similarity between Queries and Topics',
                color: token.colorPrimaryText,
                font: {
                    size: 20,
                },
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const datasetLabel = context.dataset.label || '';
                        const index = context.dataIndex;
                        const text = datasetLabel === 'Queries'
                            ? chartData.queryData.queries[index].text
                            : chartData.queryData.topics[index].text;
                        return `${datasetLabel}: ${text}`;
                    },
                },
            },
        },
    };

    const SatisfactionBar = React.memo(() => {
        const filteredData = useMemo(() => chartData.satisfaction.filter(item =>
            typeof item.satisfaction === 'number' && selectedUsers.satisfaction.includes(item.user_id)
        ), [chartData.satisfaction, selectedUsers.satisfaction]);

        const data = {
            labels: filteredData.map(item => item.user_name),
            datasets: [
                {
                    label: 'Satisfaction',
                    data: filteredData.map(item => (item.satisfaction as number) * 100), // satisfaction in %
                    backgroundColor: token.colorPrimaryActive,
                    borderColor: token.colorBgContainer,
                    borderWidth: 1,
                    barThickness: 30,
                },
            ],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            indexAxis: 'y' as const,
            scales: {
                x: {
                    beginAtZero: true,
                    max: 100,
                    title: {
                        display: true,
                        text: 'Satisfaction (%)',
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Student Name',
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Satisfaction',
                    color: token.colorPrimaryText,
                    font: {
                        size: 20,
                    },
                },
            },
        };

        return <Bar data={data} options={options} />;
    });

    const satisfactionTableColumns = [
        {
            title: 'Student Name',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'Satisfaction',
            dataIndex: 'satisfaction',
            key: 'satisfaction',
            render: (satisfaction: number | string) =>
                typeof satisfaction === 'number'
                    ? `${(satisfaction * 100).toFixed(0)}%`
                    : satisfaction,
        },
    ];

    const satisfactionTableData: SatisfactionTableDataType[] = useMemo(() =>
        chartData.satisfaction.map(item => ({
            key: item.user_id,
            userName: item.user_name,
            satisfaction: typeof item.satisfaction === 'number' ? item.satisfaction : 'Too few interactions',
        }))
        , [chartData.satisfaction]);

    const satisfactionRowSelection = {
        selectedRowKeys: selectedUsers.satisfaction,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedUsers(prev => ({ ...prev, satisfaction: selectedRowKeys as string[] }));
        },
        getCheckboxProps: (record: SatisfactionTableDataType) => ({
            disabled: typeof record.satisfaction !== 'number',
        }),
    };

    // RAG COVERAGE
    const RagCoverageBar = React.memo(() => {
        const filteredData = useMemo(() => chartData.ragCoverage.filter(item =>
            typeof item.coverage === 'number' && selectedUsers.ragCoverage.includes(item.user_id)
        ), [chartData.ragCoverage, selectedUsers.ragCoverage]);

        const data = {
            labels: filteredData.map(item => item.user_name),
            datasets: [
                {
                    label: 'Material Coverage',
                    data: filteredData.map(item => (item.coverage as number) * 100), // satisfaction in %
                    backgroundColor: token.colorPrimaryActive,
                    borderColor: token.colorBgContainer,
                    borderWidth: 1,
                    barThickness: 30,
                },
            ],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            indexAxis: 'y' as const,
            scales: {
                x: {
                    beginAtZero: true,
                    max: 100,
                    title: {
                        display: true,
                        text: 'Coverage (%)',
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Student Name',
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Material Coverage',
                    color: token.colorPrimaryText,
                    font: {
                        size: 20,
                    },
                },
            },
        };

        return <Bar data={data} options={options} />;
    });

    const ragCoverageTableColumns = [
        {
            title: 'Student Name',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'Material Coverage',
            dataIndex: 'coverage',
            key: 'coverage',
            render: (coverage: number | string) =>
                typeof coverage === 'number'
                    ? `${(coverage * 100).toFixed(0)}%`
                    : coverage,
        },
    ];

    const ragCoverageTableData: RagCoverageTableDataType[] = useMemo(() =>
        chartData.ragCoverage.map(item => ({
            key: item.user_id,
            userName: item.user_name,
            coverage: typeof item.coverage === 'number' ? item.coverage : 'Too few interactions',
        }))
        , [chartData.ragCoverage]);

    const ragCoverageRowSelection = {
        selectedRowKeys: selectedUsers.ragCoverage,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedUsers(prev => ({ ...prev, ragCoverage: selectedRowKeys as string[] }));
        },
        getCheckboxProps: (record: RagCoverageTableDataType) => ({
            disabled: typeof record.coverage !== 'number',
        }),
    };

    const AvgMessagePerDayBar = React.memo(() => {
        const filteredData = useMemo(() => chartData.avgMessagePerDay.filter(item =>
            typeof item.avg_messages_per_day === 'number' && selectedUsers.avgMessagePerDay.includes(item.user_id)
        ), [chartData.avgMessagePerDay, selectedUsers.avgMessagePerDay]);

        const data = {
            labels: filteredData.map(item => item.user_name),
            datasets: [
                {
                    label: 'Average Message Count per Day',
                    data: filteredData.map(item => (item.avg_messages_per_day as number)),
                    backgroundColor: token.colorPrimaryActive,
                    borderColor: token.colorBgContainer,
                    borderWidth: 1,
                    barThickness: 30,
                },
            ],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            indexAxis: 'y' as const,
            scales: {
                x: {
                    beginAtZero: true,
                    max: 100,
                    title: {
                        display: true,
                        text: 'Average Message Count per Day',
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Student Name',
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Average Message Count per Day',
                    color: token.colorPrimaryText,
                    font: {
                        size: 20,
                    },
                },
            },
        };

        return <Bar data={data} options={options} />;
    });

    const avgMessagePerDayTableColumns = [
        {
            title: 'Student Name',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'Average Message Count per Day',
            dataIndex: 'avg_messages_per_day',
            key: 'avg_messages_per_day',
            render: (avg_messages_per_day: number | string) =>
                typeof avg_messages_per_day === 'number'
                    ? `${(avg_messages_per_day).toFixed(0)}`
                    : avg_messages_per_day,
        },
    ];


    const avgMessagePerDayTableData: AvgMessagePerDayTableDataType[] = useMemo(() =>
        chartData.avgMessagePerDay.map(item => ({
            key: item.user_id,
            userName: item.user_name,
            avg_messages_per_day: typeof item.avg_messages_per_day === 'number' ? item.avg_messages_per_day : 'Too few interactions',
        }))
        , [chartData.avgMessagePerDay]);

    const avgMessagePerDayRowSelection = {
        selectedRowKeys: selectedUsers.avgMessagePerDay,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedUsers(prev => ({ ...prev, avgMessagePerDay: selectedRowKeys as string[] }));
        },
        getCheckboxProps: (record: AvgMessagePerDayTableDataType) => ({
            disabled: typeof record.avg_messages_per_day !== 'number',
        }),
    };

    const SourceCountsChart = React.memo(() => {
        const { token } = theme.useToken();

        const filteredData = useMemo(() =>
            chartData.sourceCounts.filter(item =>
                selectedUsers.sourceCounts.includes(item.user_id)
            ),
            [chartData.sourceCounts, selectedUsers.sourceCounts]);

        const allSources = useMemo(() =>
            [...new Set(filteredData.flatMap(student => Object.keys(student.source_counts)))],
            [filteredData]);

        const colors = useMemo(() =>
            ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#8A2BE2', '#20B2AA', '#FF69B4', '#00CED1'],
            []);

        const data = useMemo(() => ({
            labels: allSources.map(count => `${count.slice(0, 10)}...${count.slice(-7)}`),
            datasets: filteredData.map((student, index) => ({
                label: student.user_name,
                data: allSources.map(source => student.source_counts[source] || 0),
                backgroundColor: colors[index % colors.length],
                borderColor: token.colorBgContainer,
                borderWidth: 1,
                barThickness: 30,
            })),
        }), [filteredData, allSources, colors, token.colorBgContainer]);

        const options = useMemo(() => ({
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Source Names',
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                        minRotation: 50,
                        maxRotation: 85,
                        autoSkip: false,
                    },
                },
                y: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Count',
                        color: token.colorInfoText,
                    },
                    ticks: {
                        color: token.colorPrimaryText,
                    },
                },
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top' as const,
                    labels: {
                        color: token.colorPrimaryText,
                    },
                },
                title: {
                    display: true,
                    text: 'Source Counts by User',
                    color: token.colorPrimaryText,
                    font: {
                        size: 20,
                    },
                },
            },
        }), [token]);

        return <Bar data={data} options={options} />;
    });


    const sourceCountsColumns = useMemo(() => [
        {
            title: 'Student Name',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'Sources',
            dataIndex: 'source_counts',
            key: 'source_counts',
            render: (source_counts: Record<string, number>) => Object.keys(source_counts).join(', '),
        },
    ], []);

    const sourceCountsTableData: SourceCountTableDataType[] = useMemo(() =>
        chartData.sourceCounts.map(item => ({
            key: item.user_id,
            userName: item.user_name,
            source_counts: item.source_counts,
        })),
        [chartData.sourceCounts]);

    const sourceCountsRowSelection = useMemo(() => ({
        selectedRowKeys: selectedUsers.sourceCounts,
        onChange: (selectedRowKeys: React.Key[]) => {
            if (selectedRowKeys.length <= 5) {
                setSelectedUsers(prev => ({ ...prev, sourceCounts: selectedRowKeys as string[] }));
            }
        },
        getCheckboxProps: (record: SourceCountTableDataType) => ({
            disabled: selectedUsers.sourceCounts.length >= 5 && !selectedUsers.sourceCounts.includes(record.key),
        }),
    }), [selectedUsers.sourceCounts]);


    return (
        <Content style={{ padding: "2rem" }}>
            {tempCourse && <>
                <Typography.Title
                    level={1}>
                    {`${capitalize(user.config.course_terminology)} Analytics`}
                </Typography.Title>
                <Row gutter={24}>
                    <Col span={16}>
                        <Typography.Title
                            level={3}>
                            Course data
                        </Typography.Title>
                        <Typography.Paragraph>
                            Name: {tempCourse.name}
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Description: {tempCourse.description}
                        </Typography.Paragraph>
                    </Col>
                    <Col span={8}>
                        {process.env.REACT_APP_COURSE_IDS?.split(',').includes(user._id) && (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column', 
                                justifyContent: 'flex-end',
                                alignItems: 'bottom',
                                alignContent: 'bottom',
                                height: '100%'
                            }}>
                                <FlexMarginButton
                                    type="primary"
                                    onClick={handleOpenReports}
                                    style={{ marginBottom: '1rem', width: '100%' }}
                                >
                                    Open Reports
                                </FlexMarginButton>
                            </div>
                        )}

                        <Modal
                            title="Course Reports"
                            open={isReportsModalVisible}
                            onCancel={handleCloseReports}
                            footer={null}
                            width="60%"
                            style={{
                                display: 'flex',
                                top: 20,
                                justifyContent: 'center'
                            }}
                        >
                            <CourseReportsPage />
                        </Modal>
                    </Col>
                </Row>
                <CourseContext.Provider value={courseHook}>
                    <Divider
                        orientation="right"
                        orientationMargin={50}>
                    </Divider>
                    <Typography.Title
                        level={3}>
                        Select Scope
                    </Typography.Title>
                    <Row gutter={24} align="middle" style={{ marginBottom: '1rem' }}>
                        <Col span={8}>
                            <Select
                                data-testid="week-select"
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select one or multiple Weeks"
                                onChange={handleWeekChange}
                                value={selectedWeeks}
                            >
                                {tempCourse!.weeks.map((week, index) => (
                                    <Option
                                        key={week.number}
                                        value={week.number}>
                                        {week.number}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={8}>
                            <RangePicker
                                data-testid="date-range-picker"
                                style={{ width: '100%' }}
                                value={dateRange}
                                onChange={(dates) => handleDateRangeChange(dates as [Dayjs, Dayjs] | null)}
                            />
                        </Col>
                        <Col span={8}>
                            <FlexMarginButton
                                style={{ width: '100%' }}
                                disabled={!(selectedWeeks.length > 0 && dateRange !== null && tempCourse) || isLoading}
                                color={token.colorPrimary}
                                onClick={() => loadData()}>
                                {(selectedWeeks.length > 0 && dateRange !== null && tempCourse) ? 'Reload data' : 'Please select weeks and data range'}

                            </FlexMarginButton>
                        </Col>
                    </Row>
                    <Typography.Paragraph>
                        {getSelectedDates}
                    </Typography.Paragraph>
                    <Divider
                        orientation="right"
                        orientationMargin={50}>
                    </Divider>
                    {isLoading ? (
                        <Spin size='large' />
                    ) : hasLoaded ? (
                        <Col>
                            <Row gutter={16} align="top">
                                <Col span={16}>
                                    <div style={{
                                        height: '50vh',
                                        border: `1px solid ${token.colorBgContainer}`,
                                        borderRadius: '8px',
                                        padding: '20px',
                                        boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                    }}>
                                        <SatisfactionBar />
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{
                                        height: '50vh',
                                        borderRadius: '0px',
                                        padding: '0px',
                                        boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                        background: token.colorBgContainer,
                                    }}>
                                        <Table
                                            rowSelection={satisfactionRowSelection}
                                            columns={satisfactionTableColumns}
                                            dataSource={satisfactionTableData}
                                            size="small"
                                            scroll={{ y: tableHeight50 }}
                                            pagination={false}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Typography.Paragraph>
                                The satisfaction indicator is based on explicit feedback (clicking thumbs up / down) and implied feedback (based on chat messages) from student interactions with the course materials. A minimum of 10 interactions is required.
                            </Typography.Paragraph>
                            <Divider
                                orientation="right"
                                orientationMargin={50}>
                            </Divider>
                            <Row gutter={16} align="top">
                                <Col span={16}>
                                    <div style={{
                                        height: '50vh',
                                        border: `1px solid ${token.colorBgContainer}`,
                                        borderRadius: '8px',
                                        padding: '20px',
                                        boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                    }}>
                                        <RagCoverageBar />
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{
                                        height: '50vh',
                                        borderRadius: '0px',
                                        padding: '0px',
                                        boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                        background: token.colorBgContainer,
                                    }}>
                                        <Table
                                            rowSelection={ragCoverageRowSelection}
                                            columns={ragCoverageTableColumns}
                                            dataSource={ragCoverageTableData}
                                            size="small"
                                            scroll={{ y: tableHeight50 }}
                                            pagination={false}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Typography.Paragraph>
                                The material converage indicator shows, whatpercentages of the materials specified for the parts in the selected date range has been presented to the student at least once.
                            </Typography.Paragraph>
                            <Divider
                                orientation="right"
                                orientationMargin={50}>
                            </Divider>
                            <Row gutter={16} align="top">
                                <Col span={16}>
                                    <div style={{
                                        height: '50vh',
                                        border: `1px solid ${token.colorBgContainer}`,
                                        borderRadius: '8px',
                                        padding: '20px',
                                        boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                    }}>
                                        <AvgMessagePerDayBar />
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{
                                        height: '50vh',
                                        borderRadius: '0px',
                                        padding: '0px',
                                        boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                        background: token.colorBgContainer,
                                    }}>
                                        <Table
                                            rowSelection={avgMessagePerDayRowSelection}
                                            columns={avgMessagePerDayTableColumns}
                                            dataSource={avgMessagePerDayTableData}
                                            size="small"
                                            scroll={{ y: tableHeight50 }}
                                            pagination={false}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Typography.Paragraph>
                                The verage number of messages per day is calculated by taking the number of all interactions with the system and deviding by the number of days specified in the date range.
                            </Typography.Paragraph>
                            <Divider
                                orientation="right"
                                orientationMargin={50}>
                            </Divider>
                            <Row gutter={16} align="top">
                                <Col span={16}>
                                    <div style={{
                                        height: '80vh',
                                        border: `1px solid ${token.colorBgContainer}`,
                                        borderRadius: '8px',
                                        padding: '20px',
                                        boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                    }}>
                                        <SourceCountsChart />
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{
                                        height: '80vh',
                                        borderRadius: '0px',
                                        padding: '0px',
                                        boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                        background: token.colorBgContainer,
                                    }}>
                                        <Table
                                            rowSelection={sourceCountsRowSelection}
                                            columns={sourceCountsColumns}
                                            dataSource={sourceCountsTableData}
                                            size="small"
                                            scroll={{ y: tableHeight80 }}
                                            pagination={false}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Typography.Paragraph>
                                The source counts present the ranking of the most presented sources to the students in the selected date range.
                            </Typography.Paragraph>
                            <Divider
                                orientation="right"
                                orientationMargin={50}
                            />
                            <Row gutter={16} align="top">
                                <Col span={24}>
                                    <div style={{
                                        height: '50vh',
                                        border: `1px solid ${token.colorBgContainer}`,
                                        borderRadius: '8px',
                                        padding: '20px',
                                        boxShadow: `0 2px 8px ${token.colorBgContainer}`,
                                    }}>
                                        <EmbeddingScatterPlot data={pcaData} options={pcaOptions} />
                                    </div>
                                </Col>
                            </Row>
                            <Typography.Paragraph>
                                The similarity between queries and sources is depicted here as the distance between points on the graph and gives an overview of the popularity of certain topics compared to others.
                            </Typography.Paragraph>
                        </Col>
                    ) : null
                    }
                </CourseContext.Provider>
            </>}
        </Content>
    );
};

export default CourseAnalytics;
