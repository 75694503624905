import { PlusOutlined } from '@ant-design/icons';
import { styled } from '@stitches/react';
import { Button, Form, Input, Modal, message } from 'antd';
import { FormikErrors, useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createCourse } from '../../services/courses';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';
import React from 'react';
import { capitalize } from '../../utils/utils';

const CardButton = styled(Button, {
    minWidth: "100%",
    height: "75px",
    padding: "20px",
    border: "1px dashed gray",
    borderRadius: "8px",
    background: "transparent",
    color: "gray",
    gridColumn: "1 / -1",
})

interface FormValues {
    name: string;
    description: string;
}

const AddCourseCard = () => {
    const user = getUserDataFromLocalStorage();
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            description: ''
        },
        validate: (values) => {
            let errors: FormikErrors<FormValues> = {};
            if (!values.name) {
                errors.name = `Please enter a ${user.config.course_terminology.toLowerCase()} name.`;
            }
            if (!values.description) {
                errors.description = `Please enter a ${user.config.course_terminology.toLowerCase()} description.`;
            }
            return errors;
        },
        onSubmit: (values) => {
            setIsLoading(true);
            // Perform login logic by sending data to the backend
            createCourse(values.name, values.description, user._id)
                .then((course) => {
                    message.success(`${capitalize(user.config.course_terminology)} created successfully!`);
                    navigate("/courses/" + course._id);
                })
                .catch((err) => {
                    message.error(`Error creating ${user.config.course_terminology.toLowerCase()}`);
                    console.error(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });

        },
        validateOnChange: false,  // Prevent automatic validation on change
        validateOnBlur: false
    });

    return (
        <>
            <CardButton icon={<PlusOutlined />} onClick={() => setModalVisible(true)} style={{fontSize: "1.1rem", background: "rgba(255,255,255,0.1)"}}>{`Add new ${user.config.course_terminology.toLowerCase()}`}</CardButton>
            <Modal title={`Create new ${user.config.course_terminology.toLowerCase()}`} open={modalVisible} onCancel={() => setModalVisible(false)} footer={null}>
                <Form onSubmitCapture={formik.handleSubmit} style={{ width: "100%" }}>
                    <Form.Item
                        help={formik.errors.name}
                        validateStatus={formik.errors.name ? "error" : undefined}
                    >
                        <Input
                            type="text"
                            id="name"
                            placeholder={`Enter ${user.config.course_terminology.toLowerCase()} name`}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Form.Item>

                    <Form.Item
                        help={formik.errors.description}
                        validateStatus={formik.errors.description ? "error" : undefined}
                    >
                        <Input
                            type="text"
                            id="description"
                            placeholder={`Enter ${user.config.course_terminology.toLowerCase()} description`}
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Form.Item>

                    <p style={{ color: "gray" }}><i>{`You will be able to add ${user.config.course_terminology.toLowerCase()} materials later!`}</i></p>

                    <Button loading={isLoading} type="primary" htmlType="submit" size="large" style={{ width: "100%" }}>Create</Button>
                </Form>
            </Modal>
        </>
    );
};

export default AddCourseCard;
