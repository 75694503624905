import { Button, Form, Input, Modal, Radio, Rate, Space, message } from 'antd';
import { useFormik } from 'formik';
import React from 'react';
import { storeFeedback } from '../services/feedback';
import { getUserDataFromLocalStorage } from '../utils/useLocalStorage';


// Define the types for the component props
interface FeedbackModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ isOpen, setIsOpen }) => {
  const user = getUserDataFromLocalStorage();
  const [nps, setNps] = React.useState<number | undefined>(undefined);
  const [nextFeature, setNextFeature] = React.useState<string | undefined>(undefined);
  const [nextFeatureOther, setNextFeatureOther] = React.useState<string | undefined>(undefined);
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);

  function clearAll() {
    setNps(undefined);
    setNextFeature(undefined);
    setNextFeatureOther(undefined);
    formik.resetForm();
  }

  const formik = useFormik({
    initialValues: {
      nps: 0,
      nextFeature: '',
      nextFeatureOther: '',
      comments: '',
    },
    validate: (values) => {
      let errors: any = {};
      if (!nps) {
        errors.nps = 'Please enter a recommendation value!';
      }
      if (!nextFeature || (nextFeature === 'other' && !nextFeatureOther)) {
        errors.nextFeature = 'Please indicate your next feature request!';
      }
      return errors;
    },
    onSubmit: (values) => {
      setSubmitLoading(true)
      storeFeedback(nps! * 2, nextFeature === "other" ? nextFeatureOther! : nextFeature!, values.comments, user?._id)
        .then(() => {
          message.success('Feedback Submitted!');
          setIsOpen(false);
        })
        .catch(() => {
          message.error('Feedback Submission Failed!');
        })
        .finally(() => {
          setSubmitLoading(false)
          clearAll();
        })
    },
    validateOnChange: false,
    validateOnBlur: false
  });

  return (
    <Modal
      title={<h3>Infolab's Feedback Form 🚀</h3>}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
    >
      <p><i>Submitting as {user?.name}</i></p>
      <Form onSubmitCapture={formik.handleSubmit} style={{ width: "100%", display: "flex", flexDirection: "column", gap: "40px" }}>
        <div style={{ margin: "20 auto 0 auto" }}>
          <label style={{ fontWeight: 700 }} htmlFor="nps">How likely are you to recommend Infolab to a friend or colleague?</label>
          <Form.Item
            help={formik.errors.nps}
            validateStatus={formik.errors.nps ? "error" : undefined}
            style={{ maxWidth: "fit-content", margin: "0 auto", marginTop: '10px' }}
          >
            <Rate id="nps" style={{ fontSize: 30 }} allowHalf value={nps} onChange={setNps} />
          </Form.Item>
        </div>

        <div style={{ margin: "20 auto 0 auto" }}>
          <label style={{ fontWeight: 700 }} htmlFor="next-feature">What feature would you like to see next?</label>
          <Form.Item
            help={formik.errors.nextFeature}
            validateStatus={formik.errors.nextFeature ? "error" : undefined}
            style={{ margin: "0 auto", marginTop: '10px' }}
          >
            <Radio.Group id="next-feature" onChange={e => setNextFeature(e.target.value)} value={nextFeature}>
              <Space direction="vertical">
                <Radio value={'ai-week-report'}>Chat with Topics 📝</Radio>
                <Radio value={'ai-generated-exercises'}>AI Generated Exercises 🗒</Radio>
                <Radio value={'faster-inference'}>Faster inference 🏎</Radio>
                <Radio value={'other'}>
                  Other...
                  {
                    nextFeature?.startsWith('other') ? <Input
                      onChange={e => setNextFeatureOther("other: " + e.target.value)}
                      style={{ width: 200, marginLeft: 10 }} 
                      placeholder='Dreams come true!'/> 
                      : null}
                </Radio>
              </Space><samp></samp>
            </Radio.Group>

          </Form.Item>
        </div>

        <div style={{ margin: "20 auto 0 auto", width: "100%", }}>
          <label style={{ fontWeight: 700 }} htmlFor="comments">Any extra comments?</label>
          <Form.Item
            help={formik.errors.comments}
            validateStatus={formik.errors.comments ? "error" : undefined}
          >
            <Input.TextArea
              style={{ marginTop: 10 }}
              size="large"
              id="comments"
              placeholder='Please provide as much details as possible!'
              name="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </div>

        <Button loading={submitLoading} type="primary" htmlType="submit" size="large" style={{ width: "100%" }}>Submit</Button>
      </Form>
    </Modal >
  );
};

export default FeedbackModal;
