import { CheckCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, InboxOutlined, SyncOutlined } from "@ant-design/icons";
import { Spacer } from "@nextui-org/react";
import { styled } from "@stitches/react";
import { Modal, Table, Typography, Upload, UploadFile, UploadProps, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { CourseContext } from "../../screens/courses/course";
import { CourseFile, deleteCourseFile, extractAudioAndConvertToAudioFile } from "../../services/courses";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";
import { formatDate } from "../../utils/utils";
import React from "react";
import { supportedFormats } from "../../config";
import { FlexMarginButton } from "../basic/buttons";

const { confirm } = Modal;
const { Dragger } = Upload;

const StyledDragger = styled(Dragger, {
    '& .ant-upload-drag': {
        height: 'auto'
    }
})

export const Materials = () => {
    const user = getUserDataFromLocalStorage();
    const { tempCourse: course, courseFiles, setCourseFiles, fetchCourseFiles, checkFileStatusAfterUpload } = useContext(CourseContext)
    const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
    const [uploadTimer, setUploadTimer] = useState(new Date());

    useEffect(() => {
        if (uploadTimer.getTime() > Date.now()) {
            checkFileStatusAfterUpload(uploadTimer);
        }
    }, [uploadTimer]);


    useEffect(() => {
        fetchCourseFiles(course!._id);
    }, []);

    const handleDeleteFile = (filename: string, fileId: string) => {
        confirm({
            title: React.createElement('span', null, `Are you sure you want to delete the file ${filename}?`),
            icon: <ExclamationCircleOutlined />,
            content: 'This operation cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleConfirmDelete(fileId);
            },
        });
    };

    const handleConfirmDelete = (fileId: string) => {
        deleteCourseFile(fileId, course!._id)
            .then((data) => {
                message.success('File deleted successfully.', 6);
                setCourseFiles((prevRows) => prevRows.filter((row: any) => row._id !== fileId));
            })
            .catch((error) => {
                message.error('Error deleting file.');
            });
    };

    const handleVideoFileBeforeUpload = async (file: UploadFile): Promise<boolean | Promise<UploadFile>> => {
        if (file.type === 'video/mp4') {
            try {
                // Extract audio from the video file
                const audioFile = await extractAudioAndConvertToAudioFile(file);
                // Return a promise that resolves to the audio file
                return audioFile;
            } catch (error) {
                // Handle the error appropriately, for example by showing a message
                message.error('Failed to extract audio from the video file.');
                return false; // Return false to stop the upload
            }
        }
        // If not a video, return the original file
        return file;
    };

    const uploadProps: UploadProps = {
        action: `${process.env.REACT_APP_BACKEND_URL}/upload`,
        onChange(info: any) {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`File uploaded successfully!`);
                // set uploadTimer to now + 10 min
                setUploadTimer(new Date(Date.now() + 30 * 60000));
            } else if (status === 'error') {
                let errorMessage = 'File upload failed!';
                if (info.file.response && info.file.response.detail) {
                    errorMessage += ' ' + info.file.response.detail;
                }
                message.error(errorMessage);
            }
            // Remove uploaded file notice after 5 seconds
            setTimeout(() => { setFileList([]); }, 10000);
            // Update the state with the new file list
            let newFileList: UploadFile<any>[] = [...info.fileList];
            setFileList(newFileList as UploadFile<any>[]);
        },
        fileList: fileList,  // Controlled fileList to ensure we can clear it
        headers: {
            userid: user?._id || '',
            courseid: course!._id || '',
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        name: 'course_files',
        multiple: true,
        beforeUpload(file) {
            return new Promise((resolve, reject) => {
                // Check if the file already exists in the fileList
                const fileExists = courseFiles.some(existingFile => existingFile.file_name === file.name);
                if (fileExists) {
                    message.error('File already exists!');
                    reject();
                    return;
                }
                const fileFormat = `.${file.name.split('.').pop()?.toLowerCase()}`;
                if (!fileFormat || !supportedFormats.includes(fileFormat)) {
                    message.error('Unsupported file format!');
                    reject();
                    return;
                }

                handleVideoFileBeforeUpload(file).then((result) => {
                    resolve(result as any);
                });

            });
        },
    };

    return (<>
        <h2>Materials</h2>
        <StyledDragger {...uploadProps} data-testid='file-upload'>
            <p className="ant-upload-drag-icon" data-testid='file-upload-icon'>
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
                Supported formats: {supportedFormats.join(', ').toUpperCase()}. <br />
                All video formats will be converted to WAV. <br />
                The formats DOC and ODT will be converted to PDF.
            </p>
        </StyledDragger>
        <Spacer y={10} />
        <Typography.Paragraph>
            Uploaded Files will appear in the table below. Processing of large files can take up to an hour, but you can already start using files in processing for the course specifications.
        </Typography.Paragraph>
        <Typography.Paragraph>
            Should the upload not be successful, please inform via the quick support button the development team to sort it out quickly!
        </Typography.Paragraph>
        <Table
            dataSource={courseFiles}
            columns={[
                {
                    title: '',
                    dataIndex: 'delete',
                    key: 'delete',
                    render: (_: any, record: CourseFile) => (
                        <FlexMarginButton
                            data-testid="delete-button"
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                handleDeleteFile(record.file_name, record._id)
                            }}
                        />
                    ),
                    width: '10%',
                },
                {
                    title: 'Name',
                    dataIndex: 'file_name',
                    key: 'file_name',
                    width: '45%',
                },
                {
                    title: 'Uploaded at',
                    dataIndex: 'created_at',
                    key: 'created_at',
                    render: (text: string) => {
                        return formatDate(text);
                    },
                    width: '30%',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    render: (status: string) => {
                        if (status === 'available') {
                            return <CheckCircleOutlined style={{ color: 'green' }} />;
                        } else if (status === 'unavailable') {
                            return <ExclamationCircleOutlined style={{ color: 'orange' }} />;
                        } else {
                            return <SyncOutlined spin />;
                        }
                    },
                    width: '15%',
                },
            ]}
            rowKey={record => record._id}
            pagination={{
                defaultPageSize: 10,
            }}
        />
    </>)
};