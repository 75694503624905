import React, { useState } from "react";
import { getFileInConversation } from "../../services/conversations";
import { message, Tooltip } from "antd";
import { FlexMarginButton } from "../basic/buttons";
import { DownloadOutlined } from "@ant-design/icons";
import { styled } from "@stitches/react";


const SourceButtonContainer = styled("div", {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "0.5rem",
    margin: "0.5rem 0",
});

export interface SourceItem {
    fileId: string;
    sourceText: string;
}


const SourceButton = ({ conversationId, fileId, sourceText }: {
    conversationId: string;
    fileId: string;
    sourceText: string;
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleDownload = async () => {
        try {
            setIsLoading(true);
            const response = await getFileInConversation(conversationId, fileId);
            
            // Extract filename from Content-Disposition header
            const fileNameHeader = response.headers.get("X-Filename");
            let filename = `source_${fileId}`; // fallback filename
            
            if (fileNameHeader) {
                const filenameMatch = fileNameHeader.match(/filename="(.+?)"/);
                if (filenameMatch) {
                    filename = filenameMatch[1];
                }
            }
            
            // Get the correct mime type from Content-Type header
            const contentType = response.headers.get('content-type') || 'application/octet-stream';
            
            // Convert response to blob
            const blob = new Blob([response.data], { type: contentType });
    
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            link.remove();
            
            // in case the Blob uses a lot of memory
            setTimeout(() => URL.revokeObjectURL(link.href), 30000);
    
            // Cleanup
            URL.revokeObjectURL(link.href);
            message.success('Download started');
        } catch (error) {
            console.error('Error downloading file:', error);
            message.error('Failed to download file');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <SourceButtonContainer>
            <Tooltip title="Download source">
                <FlexMarginButton
                    type="text"
                    icon={<DownloadOutlined />}
                    onClick={handleDownload}
                    loading={isLoading}
                >
                    {sourceText}
                </FlexMarginButton>
            </Tooltip>
        </SourceButtonContainer>
    );
};


export default SourceButton;