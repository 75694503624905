
import { styled } from '@stitches/react';

export const AbsoluteCenter = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    boxSizing: 'border-box',
    padding: "20px"
});

export const FullPage = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1000,
  background: 'black',
  width: "100vw",
  height: "100vh",
  overflow: 'hidden', // Prevent scrolling
  touchAction: 'none', // Prevent zooming and panning on touch devices
})

export const HiddenWhenScreenIsBigPage = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1000,
  background: 'black',
  width: "100vw",
  height: "100vh",
  overflow: 'hidden', // Prevent scrolling
  touchAction: 'none', // Prevent zooming and panning on touch devices
  '@media (min-width: 1024px)': {
      display: 'none',
  },
})

export const Flex = styled('div', {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  
    variants: {
      direction: {
        column: {
          flexDirection: 'column',
        },
        row: {
          flexDirection: 'row',
        },
      },
      justify: {
        center: {
          justifyContent: 'center',
        },
        start: {
          justifyContent: 'flex-start',
        },
        end: {
          justifyContent: 'flex-end',
        },
        between: {
          justifyContent: 'space-between',
        },
        around: {
          justifyContent: 'space-around',
        },
      },
      align: {
        center: {
          alignItems: 'center',
        },
        start: {
          alignItems: 'flex-start',
        },
        end: {
          alignItems: 'flex-end',
        },
        stretch: {
          alignItems: 'stretch',
        },
      },
      wrap: {
        wrap: {
          flexWrap: 'wrap',
        },
        nowrap: {
          flexWrap: 'nowrap',
        },
      },
    },
  });
  