import React from 'react';
import { Modal, Menu, MenuProps } from 'antd';
import { capitalize } from '../../utils/utils';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';

type AssignmentSelectionOverlayProps = {
    visible: boolean;
    weeks: { number: number; assignment: string }[];
    onWeekSelect: (weekNumber: number) => void;
    onClose: () => void;
};

const AssignmentSelectionOverlay: React.FC<AssignmentSelectionOverlayProps> = ({ visible, weeks, onWeekSelect, onClose }) => {
    const handleMenuClick: MenuProps['onClick'] = (e) => {
        const selectedWeekNumber = parseInt(e.key, 10);
        onWeekSelect(selectedWeekNumber);
    };
    const user = getUserDataFromLocalStorage();

    const menuItems = weeks.map((week) => ({
        label: `${capitalize(user.config.module_terminology)} ${week.number}`,
        key: week.number.toString(),
    }));

    return (
        <Modal
            title={<div style={{ textAlign: 'center' }}>Select Assignment</div>}
            open={visible}
            onCancel={onClose}
            footer={null}
        >
            <Menu
                selectedKeys={weeks.map(String)}
                onClick={handleMenuClick}
                items={menuItems}
                style={{ textAlign: 'center' }}
            />
        </Modal>
    );
};

export default AssignmentSelectionOverlay;
