import React from 'react';
import { Table } from 'antd';
import { FlexMarginButton } from '../basic/buttons';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Template, VariableDescription } from '../../services/templates';


interface TemplateTableProps {
    templates: Template[];
    handleEditTemplate: (template: Template) => void;
    handleDeleteTemplate: (ui_key: string) => void;
}

const TemplateTable: React.FC<TemplateTableProps> = ({ templates, handleEditTemplate, handleDeleteTemplate }) => {
    return (
        <div>
            <Table
                dataSource={templates}
                columns={[
                    { title: 'Name', dataIndex: 'name', key: 'name', width: '20%', },
                    { title: 'Variables', dataIndex: 'variables', key: 'variables', render: (variables) => variables.map((v: VariableDescription) => v.variable).join('\n') },
                    { title: 'Descriptions', dataIndex: 'variables', key: 'desriptions', render: (variables) => variables.map((v: VariableDescription ) => v.description).join('\n') },
                    {
                        title: 'Actions',
                        key: 'actions',
                        render: (_, record) => (
                            <div style={{ display: 'flex', gap: '8px' }}>
                                <FlexMarginButton onClick={() => handleEditTemplate(record)}>
                                    <EditOutlined />
                                </FlexMarginButton>
                                <FlexMarginButton onClick={() => handleDeleteTemplate(record.ui_key)}>
                                    <DeleteOutlined />
                                </FlexMarginButton>
                            </div>
                        ),
                        width: '20%',
                    },
                ]}
            />
        </div>
    );
};

export default TemplateTable;