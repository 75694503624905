import { ExclamationCircleOutlined } from '@ant-design/icons';
import { NextUIProvider } from "@nextui-org/react";
import { ConfigProvider, Modal, Spin, theme } from 'antd';
import { createContext, useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ChatHistory from './screens/chat/chat-history';
import Administration from './screens/administration/administration';
import AssignedStudents from './screens/assigned-students/assigned-students';
import Header from "./components/header";
import HiddenWhenMobile from './screens/hidden-when-mobile';
import BackendUnavailable from './screens/backend-unavailable';
import { AbsoluteCenter } from './components/styles';
import ChatInterface from './screens/chat/chat-interface';
import StudentCourseOverview from './screens/student-course-overview';
import Course from './screens/courses/course';
import CourseOverview from './screens/courses/course-overview';
import UsersPage from './screens/UserTemplatePage';
import HealthCheck from './screens/HealthCheck';
import LoginScreen from './screens/login/login';
import SignupScreen from './screens/signup/signup';
import TechnicalAccountSignupScreen from './screens/signup/technical_account_signup';
import StripeCheckout from './screens/checkout/StripeCheckout';
import Return from './screens/checkout/Return';
import SubscriptionManagement from './screens/subsciription/SubscriptionManagement';
import UserSettingsPage from './screens/settings/UserSettingsPage';
import { fetchUserType, verifyToken } from './services/user';
import { getUserDataFromLocalStorage } from './utils/useLocalStorage';
import { getAnalyticsCopilotInitialMessage } from './utils/utils';
import { CourseAnalytics } from './screens/analytics/course-analytics';
import { dark_theme_token, default_theme_token, dark_theme_components, default_theme_components } from './config';
import { logout } from './services/auth-axios';
import { ErrorBoundary } from "react-error-boundary";
import FallbackComponent from './components/fallback';

const { confirm } = Modal;

export const UserContext = createContext(null);

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isBackendAvailable, setIsBackendAvailable] = useState(true);

  const checkBackendHealth = async () => {
    try {
      if (!process.env.REACT_APP_BACKEND_URL) {
        console.error('REACT_APP_BACKEND_URL is not set');
        return;
      }
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/health`);
      if (res.ok) {
          setIsBackendAvailable(true);
      }
    } catch (error) {
      console.error('Backend health check failed:', error);
      setIsBackendAvailable(false);
    }
  };

  useEffect(() => {
    checkBackendHealth();
    const healthCheckInterval = setInterval(checkBackendHealth, 30000);
    checkAuthStatus();
    return () => clearInterval(healthCheckInterval);
  }, []);

  const checkAuthStatus = async () => {
    const isValidToken = await verifyToken();
    setIsLoggedIn(isValidToken);
    if (isValidToken) {
      // Optionally fetch and set user type here if your verification endpoint provides this information
      const userData = getUserDataFromLocalStorage();
      setUser(userData)
      if (userData?._id) {
        fetchUserType(userData._id).then(setUserType);
      }
    }
  };

  const loginCallback = () => {
    checkAuthStatus()
  };

  // Function to handle user logout
  const handleLogout = () => {
    logout();
    setIsLoggedIn(false);
  };

  const confirmLogout = () => {
    confirm({
      title: 'Are you sure you want to logout?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleLogout()
      },
      onCancel() {
        setLogoutOpen(false);
      },
    });
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setIsDarkMode(savedTheme === 'dark');
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'default');
  };

  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={(error, info) => {
        console.error("Caught an error:", error, info);
      }}
      onReset={() => {
        window.location.reload();
      }}>
      <NextUIProvider>
        <ConfigProvider theme={{
          algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
          token: isDarkMode ? dark_theme_token : default_theme_token,
          components: isDarkMode ? dark_theme_components : default_theme_components,
        }}>
          <main className={`${isDarkMode ? 'dark' : ''} text-foreground bg-background`} style={{ height: '100vh' }}>
            <HiddenWhenMobile />
            {!isBackendAvailable ? (
              <BackendUnavailable />
            ) : (
              isLoggedIn ? (
                <>
                  {userType ?
                    <>
                      <UserContext.Provider value={{ type: userType, ...user }}>
                        <Router>
                          <Header onLogout={() => confirmLogout()} isDarkMode={isDarkMode} toggleTheme={toggleTheme}>
                            <Routes>
                              <Route path="/" element={<Navigate replace to="/courses" />} />
                              <Route path="/courses" element={['student', 'technical'].includes(userType) ? <StudentCourseOverview /> : <CourseOverview />} />
                              <Route path="/courses/:userID/get_user_courses" element={<CourseOverview />} />
                              <Route path="/courses/:courseID" element={<Course />} />
                              <Route path="/chat/:chatID" element={<ChatInterface type="content" endpointUrl="conversations" />} />
                              <Route path="/chat-history" element={<ChatHistory />} />
                              <Route path="/assigned-students" element={<AssignedStudents endpointUrl="assigned-students" />} />
                              <Route path="/administration" element={<Administration endpointUrl="administration" />} />
                              <Route path="/analytics-copilot/:chatID"
                                element={<ChatInterface
                                  type="analytics"
                                  endpointUrl="analytics-copilot"
                                  initialMessage={getAnalyticsCopilotInitialMessage()}
                                />} />
                              <Route path="/analytics/:courseID" element={<CourseAnalytics />} />
                              <Route path="/users" element={<UsersPage />} />
                              <Route path="/settings" element={<UserSettingsPage />} />
                              <Route path="/subscription" element={<SubscriptionManagement />} />
                              <Route path="*" element={<Navigate replace to="/" />} />
                            </Routes>
                          </Header>
                        </Router >
                      </UserContext.Provider>
                    </>
                    : <AbsoluteCenter><Spin size="large" /></AbsoluteCenter>
                  }
                </>
              ) : (
                <Router>
                  <Routes>
                    <Route path="/login" element={<LoginScreen loginCallback={loginCallback} isDarkMode={isDarkMode} toggleTheme={toggleTheme} />} />
                    <Route path="/signup" element={<SignupScreen loginCallback={loginCallback} isDarkMode={isDarkMode} toggleTheme={toggleTheme} />} />
                    <Route path="/technical-account-signup" element={<TechnicalAccountSignupScreen loginCallback={loginCallback} isDarkMode={isDarkMode} toggleTheme={toggleTheme} />} />
                    <Route path="/checkout" element={<StripeCheckout />} />
                    <Route path="/return" element={<Return loginCallback={loginCallback} isDarkMode={isDarkMode} />} />
                    <Route path="*" element={<Navigate replace to="/login" />} />
                    <Route path="/health" element={<HealthCheck />} />
                  </Routes>
                </Router>
              )
            )
            }
          </main>
        </ConfigProvider>
      </NextUIProvider>
    </ErrorBoundary>
  );
}

export default App;