import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setupGlobalErrorHandlers } from './components/error-handlers';
import FallbackComponent from './components/fallback';
import { ErrorBoundary } from 'react-error-boundary';

setupGlobalErrorHandlers();

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement ? rootElement : document.createElement('div'));
root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
