import { Container, SignupFormContainer, TopImages } from '../../components/session-styles';
import TechnicalAccountSignupForm from '../../components/signup/technical-account-signup-form';
import { AbsoluteCenter } from '../../components/styles';
import { SignupProps } from './signup';

const TechnicalAccountSignupScreen: React.FC<SignupProps> = ({ loginCallback, isDarkMode, toggleTheme }) => {
    console.log("TechnicalAccountSignupScreen is rendering");
    return (
        <>
            <TopImages />
            <AbsoluteCenter>
                <Container>
                    <SignupFormContainer>
                        <TechnicalAccountSignupForm
                            loginCallback={loginCallback}
                            isDarkMode={isDarkMode}
                            toggleTheme={toggleTheme} />
                    </SignupFormContainer>
                </Container>
            </AbsoluteCenter>
        </>
    );
};

export default TechnicalAccountSignupScreen;